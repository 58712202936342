import React, {useState, useCallback, useEffect} from 'react';
import {useNavigate, useLocation, Routes, Route, useNavigationType} from 'react-router-dom';

import {useHttpClient} from './common/hook/http';

import {ContextAuth} from './common/context/context-auth';
import {ContextToastProvider} from './common/context/context-toast';
import {ContextMobileDatePickerProvider} from './common/context/context-mobile-date-picker';
import {ContextBreadcrumbProvider} from './common/context/context-breadcrumb';

import getRoutes, {routesArray} from './common/route';

import Header from './public/layout/Header/Header';
import Main from './public/layout/Main/Main';
import Footer from './public/layout/Footer/Footer';

import Error404 from './public/page/Error404/Error404';
import AccessDenied from './public/page/AccessDenied/AccessDenied';

import ModalLoader from './public/modal/Loader/Loader';

import './asset/style/style-rtl.scss';

const App = () => {
    const {callAPI} = useHttpClient()
    const navigate = useNavigate();
    const location = useLocation();
    const navigationType = useNavigationType()

    // ------------------------------------------------------------------------------------------------------------ auth

    const [appData, setAppData] = useState({menu: null, routes: null, token: null, user: null, parameters: null})
    const [appLoaded, setAppLoaded] = useState(false)

    const login = useCallback(async (storedMenu, storedToken, storedUser, realLogin) => {

        // منو می تواند سطوح دسترسی داشته باشد که آیتم های قابل نمایش برای هر کاربر هنگام لاگین دریافت می شوند.
        // در فایل common/route.js آیتم های routesPublic قرار دارند و route هایی هستند که در منو اپلیکیشن وجود ندارند.
        let routesTemp

        try {
            routesTemp = await getRoutes(routesArray);
        } catch (err) {
            throw err
        }

        if (!storedMenu) storedMenu = routesArray.filter(({menu}) => menu);

        let parametersTemp = realLogin ? appData.parameters : {}

        if (!realLogin)
            try {

                const responseData = await callAPI(
                    `parameters`,
                    undefined,
                    undefined,
                    undefined,
                    storedToken
                );

                if (responseData.message) {

                } else {

                    parametersTemp.header = {}
                    parametersTemp.header.menu = responseData.menus
                    parametersTemp.header.entertainment = responseData.entertainments
                    parametersTemp.footer = {}
                    parametersTemp.footer.contactUs = responseData.contactUs
                    parametersTemp.footer.socialMedia = responseData.socialMedia

                    if (storedUser)
                        storedUser = {...storedUser,
                            availableCredit: responseData?.customer?.availableCredit,
                            availablePoint: responseData?.customer?.availablePoint,
                            availableTicket: responseData?.customer?.availableTicket
                        }

                }

            } catch (err) {
                throw err
            }

        setAppData({menu: storedMenu, routes: routesTemp, token: storedToken, user: storedUser, parameters: parametersTemp})

        if (storedToken) localStorage.setItem('auth', JSON.stringify({menu: storedMenu, token: storedToken, user: storedUser}))

        setAppLoaded(true);

    }, []);

    useEffect(() => {

        const storedLocalStorage = JSON.parse(localStorage.getItem('auth'))

        if (storedLocalStorage && storedLocalStorage.token) login(storedLocalStorage.menu, storedLocalStorage.token, storedLocalStorage.user)
        else login(null, null, null)

    }, []);

    const logout = useCallback(() => {

        // مقدار appData به عنوان dependency وجود دارد تا در هنگام logout به مقادیر آن دسترسی باشد

        setAppData({...appData, menu: routesArray.filter(({menu}) => menu), token: null, user: null})

        localStorage.removeItem('auth')

        navigate('/')

    }, [appData]);

    const editUser = useCallback(editData => {

        let userTemp = {...appData.user, ...editData}

        setAppData(appData => ({...appData, user: userTemp}))

        localStorage.setItem('auth', JSON.stringify({...appData, user: userTemp}))

    }, [appData]);

    // ------------------------------------------------------------------------------------------------ set <HTML/> data

    useEffect(() => {

        let currentRoute = location.pathname === '/' ? 'home' : location.pathname.endsWith('/') ? location.pathname.substring(1).slice(0, -1) : location.pathname.substring(1);

        const getRoute = (array, term) => {
            array.some(item => {
                if (item.alias === term) return currentRoute = item;
                else if (item.children) return getRoute(item.children, term)
            })
        }

        getRoute(routesArray, currentRoute)

        if (currentRoute.id) {

            // با خواندن اطلاعات از local storage و یا لاگین مقدار token تغییر می کند
            if (currentRoute.access === 'private' && !appData.token) {

                document.documentElement.dataset.access = 'public'
                document.documentElement.dataset.page = 'access-denied'

            } else {

                document.documentElement.dataset.access = currentRoute.access
                // حالت خاصی برای route های account در نظر گرفته شد تا از data-page تکراری جلوگیری شود
                document.documentElement.dataset.page = currentRoute.alias.startsWith('account') ? currentRoute.alias.replace('/', '-') : currentRoute.path === '/' ? 'home' : currentRoute.path.replace('/', '-')

            }

        }

        if (navigationType === 'PUSH') window.scrollTo(0, 0)

    }, [location.pathname, appData.token])

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <>
            {
                appLoaded ? (
                    <ContextAuth.Provider value={{token: appData.token, user: appData.user, menu: appData.menu, routes: appData.routes, login: login, logout: logout, editUser: editUser}}>
                        <ContextToastProvider>
                            <ContextMobileDatePickerProvider>
                                <Header data={appData.parameters?.header}/>
                                <Main>
                                    <ContextBreadcrumbProvider>
                                        <Routes>
                                            {
                                                appData.routes.map(({path, component, id, children, access}) => (
                                                    <React.Fragment key={id}>
                                                        {
                                                            access === 'private' ? (
                                                                <Route path={path} element={appData.token ? component : <AccessDenied/>}>
                                                                    {
                                                                        children ? (
                                                                            children.map(({path, component, id}) => (
                                                                                <Route path={path} element={component} key={id}/>
                                                                            ))
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </Route>
                                                            ) : (
                                                                <Route path={path} element={component}>
                                                                    {
                                                                        children ? (
                                                                            children.map(({path, component, id}) => (
                                                                                <Route path={path} element={component} key={id}/>
                                                                            ))
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </Route>
                                                            )
                                                        }
                                                    </React.Fragment>
                                                ))
                                            }
                                            <Route path="*" element={<Error404/>}/>
                                        </Routes>
                                    </ContextBreadcrumbProvider>
                                </Main>
                                <Footer data={appData.parameters?.footer}/>
                            </ContextMobileDatePickerProvider>
                        </ContextToastProvider>
                    </ContextAuth.Provider>
                ) : (
                    // برای اینکه body در ابتدا مقدار padding بگیرد و بعد از بسته شدن ModalLoader اسکرول صفحه به آن حرکت ندهد
                    <div style={{height: '150vh'}}/>
                )
            }
            <ModalLoader show={appLoaded}/>
        </>
    );
}

export default App;