import React, {useEffect, useState} from 'react';

import {Modal} from 'react-bootstrap';

import GlobalConst from '../../../common/global-const';

import {ReactComponent as ImgLogo} from '../../../asset/img/micamall-logo-v.svg';
import {ReactComponent as ImgLoader} from '../../../asset/img/icon/loader.svg';

import './Loader.scss';

const LoaderApp = props => {

    // -----------------------------------------------------------------------------------------------------------------

    const onShowHandler = () => {

        document.getElementById('modalLoader').style.paddingRight = document.querySelector('body').style.paddingRight
        document.querySelector('#modalLoader .modal-content > figure > span:nth-child(2)').style.backgroundPosition = `calc(50%${document.querySelector('body').style.paddingRight ? ` - (${document.querySelector('body').style.paddingRight} / 2)` : ''}) top`

    }

    const [show, setShow] = useState(true)

    useEffect(() => {

        if (props.show) {

            // تاخیر جهت نمایش کوتاه ModalLoader
            let timeout = setTimeout(() => setShow(false), 500)

            return () => clearTimeout(timeout);

        }

    }, [props.show])

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <Modal id="modalLoader" show={show} fullscreen onShow={onShowHandler} backdrop={false} className="ltr bg-white" dialogClassName="rtl" contentClassName="bg-transparent">
            <figure className="mb-0 position-absolute top-0 bottom-0 end-0 z-index-n1 filter-warm w-100vw">
                <span className="filter-level"/>
                <span className="position-absolute top-0 start-0 bottom-0 end-0" style={{backgroundImage: `url(${GlobalConst.cdn}public-portal/img/login-bg.jpg)`}}/>
                <span className="filter-gradient"/>
            </figure>
            <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
                <ImgLogo width={70} height={null} className="mb-4"/>
                <ImgLoader width={48} height={48}/>
            </Modal.Body>
        </Modal>
    )
}

export default LoaderApp;