const cardNumber = (value) => {

    if (value) {

        let valueTemp = value.split('-').join('');

        if (valueTemp.length > 0) return valueTemp.match(new RegExp('.{1,4}', 'g')).join('-');

    } else return value

}

export default cardNumber