import validator from 'validator';

const typeNotEmpty = 'NOT_EMPTY';
const typeEmail = 'EMAIL';
const typeMobile = 'MOBILE';
const typeTelephone = 'TELEPHONE';
const typeLength = 'LENGTH';
const typeNationalCode = 'NATIONAL_CODE';
const typePostalCode = 'POSTAL_CODE';
const typeNotRequired = 'NOT_REQUIRED';
const typeUrl = 'URL';

export const validatorNotEmpty = () => ({
    type: typeNotEmpty
})

export const validatorEmail = () => ({
    type: typeEmail
})

export const validatorMobile = () => ({
    type: typeMobile
})

export const validatorTelephone = () => ({
    type: typeTelephone
})

export const validatorLength = range => ({
    type: typeLength,
    range: range
})

export const validatorNationalCode = () => ({
    type: typeNationalCode
})

export const validatorPostalCode = () => ({
    type: typePostalCode
})

export const validatorNotRequired = () => ({
    type: typeNotRequired
})

export const validatorUrl = () => ({
    type: typeUrl
})

// ----------------------------------------------------------------------------------------------------------- functions

const checkNationalCode = value => {
    let check = parseInt(value[9]);
    let sum = 0;

    for (let i = 0; i < 9; i++) {
        sum += parseInt(value[i]) * (10 - i);
    }

    sum %= 11;

    return (sum < 2 && check === sum) || (sum >= 2 && check + sum === 11);
}

const checkMobile = value => {
    return !!(value.match(/^09\d{9}$/) || value.match(/^9\d{9}$/));
}

const checkTelephone = value => {
    return !!value.match(/^0\d{10}$/);
}

// ---------------------------------------------------------------------------------------------------------------------

export const utilValidate = (value, validators) => {

    let isValid = true

    for (const item of validators) {
        if (isValid) {
            if (item.type === typeNotEmpty)
                isValid = !validator.isEmpty(String(value))
            if (item.type === typeEmail)
                isValid = validator.isEmail(value)
            if (item.type === typeMobile)
                isValid = checkMobile(value)
            if (item.type === typeTelephone)
                isValid = checkTelephone(value)
            if (item.type === typeLength)
                isValid = validator.isLength(value, {min: item.range[0] || 0, max: item.range[1] || undefined})
            if (item.type === typeNationalCode)
                isValid = checkNationalCode(value)
            if (item.type === typePostalCode)
                isValid = validator.isPostalCode(value, 'IR')
            if (item.type === typeUrl)
                isValid = validator.isURL(value)
        } else if (item.type === typeNotRequired) {
            if (value === '')
                isValid = validator.isEmpty(value)
        }
    }

    return isValid

}