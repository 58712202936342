const setNativeValue = (id, value) => {
    // let nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
    // nativeInputValueSetter.call(document.getElementById(id), value);
    // let ev2 = new Event('input', { bubbles: true});
    // document.getElementById(id).dispatchEvent(ev2);

    const valueSetter = Object.getOwnPropertyDescriptor(document.getElementById(id), 'value').set;
    const prototype = Object.getPrototypeOf(document.getElementById(id));
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter.call(document.getElementById(id), value);
    } else {
        valueSetter.call(document.getElementById(id), value);
    }

    document.getElementById(id).dispatchEvent(new Event('input', {bubbles: true}));
}

export default setNativeValue
