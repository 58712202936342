import React, {useEffect, useState} from 'react';

const CountDown = props => {

    // props.timer === -1 pause
    // props.timer === 0 stop
    // props.timer > 0 شمردن

    const [countDown, setCountDown] = useState(null);

    const {onEnd} = props;

    useEffect(() => {
        if (countDown === 0)
            onEnd();
    }, [countDown, onEnd]);

    useEffect(() => {

        if (props.timer !== -1)
            setCountDown(() => props.timer);

        const countDownTimer = setInterval(() => {

            setCountDown(countDown => {
                if (props.timer === 0 || props.timer === -1) {
                    clearInterval(countDownTimer);
                    return countDown;
                }
                if (countDown === 1) {
                    clearInterval(countDownTimer);
                }
                return countDown - 1;
            });

        }, 1000);

        return () => clearInterval(countDownTimer);

    }, [props.timer]);

    return (
        <>{countDown}</>
    )
}

export default CountDown;
