import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {Breadcrumb, Col, Container, Row} from 'react-bootstrap';
import {useLocation, Link} from'react-router-dom';

import {ContextAuth} from './context-auth';

const ContextBreadcrumb = createContext({
    callBreadcrumb: () => {}
});

export default ContextBreadcrumb;

export const ContextBreadcrumbProvider = props => {
    const location = useLocation()
    const auth = useContext(ContextAuth)

    // -----------------------------------------------------------------------------------------------------------------

    const [breadcrumb, setBreadcrumb] = useState([])

    const renderBreadcrumb = data => {

        const pathnameArray = location.pathname.split('/').filter(item => (item !== '' && item !== '/'))
        const pathnameCurrent = [...pathnameArray].join('/');

        let breadcrumbTemp = []

        pathnameArray.map((item, index) => {

            if (index === (pathnameArray.length - 1)) {

                if (auth.routes.find(({alias}) => alias === pathnameCurrent))
                    breadcrumbTemp.push(<Breadcrumb.Item active>{auth.routes.find(({alias}) => alias === pathnameCurrent).title}</Breadcrumb.Item>);
                // متغیر data زمانی مقدار دارد که route مربوطه وجود نداشته باشد
                else if (data) {

                    // اگر بصورت آرایه دریافت شد که حالت چند لولی خواهد بود
                    if (Array.isArray(data)) {

                        data.map((item2, index2) => {

                            if (index2 === (data.length - 1)) breadcrumbTemp.push(<Breadcrumb.Item active>{item2.title}</Breadcrumb.Item>);
                            else breadcrumbTemp.push(<Breadcrumb.Item linkAs={Link} linkProps={{to: item2.alias, className: 'text-decoration-none'}}>{item2.title}</Breadcrumb.Item>)

                        })

                    } else breadcrumbTemp.push(<Breadcrumb.Item active>{data}</Breadcrumb.Item>);

                }

            } else {
                // جهت سه سطحی شدن
                let itemTemp = index === 0 ? item : `${pathnameArray[0]}/${item}`

                if (auth.routes.find(({alias}) => alias === itemTemp))
                    breadcrumbTemp.push(<Breadcrumb.Item linkAs={Link} linkProps={{to: auth.routes.find(({alias}) => alias === itemTemp).alias, className: 'text-decoration-none'}}>{auth.routes.find(({alias}) => alias === itemTemp).title}</Breadcrumb.Item>)
            }

        })

        setBreadcrumb(breadcrumbTemp)

    }

    useEffect(() => {

        // با تغییر route اگر route وجود داشت اقدام به ساخت خودکار breadcrumb می کند، در غیر اینصورت منتظر callBreadcrumb خواهد بود
        if (auth.routes.find(({alias}) => alias === location.pathname.substring(1)))
            renderBreadcrumb()

    }, [location.pathname])

    const callBreadcrumb = useCallback(item => {

        const timeout = setTimeout(() => {

            renderBreadcrumb(item)
            clearTimeout(timeout)

        }, 0)

        // برای deps مقدار location.pathname قرار داده شده تا در تابع renderBreadcrumb مقادیر بروز باشد
    }, [location.pathname]);

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <ContextBreadcrumb.Provider value={{callBreadcrumb: callBreadcrumb}}>
            {
                location.pathname !== '/' && (
                    <div className="position-relative d-none d-md-block breadcrumb-wrapper">
                        <div className="overflow-hidden">
                            <Container fluid="lg" className="position-relative">
                                <Row className="pt-3">
                                    <Col xs={12}>
                                        <Breadcrumb className="fs-8">
                                            <Breadcrumb.Item linkAs={Link} linkProps={{to: '/', className: 'text-decoration-none'}}>صفحه نخست</Breadcrumb.Item>
                                            {
                                                breadcrumb.map((item, index) => {
                                                    return (
                                                        <React.Fragment key={index}>{item}</React.Fragment>
                                                    )
                                                })
                                            }
                                        </Breadcrumb>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                )
            }
            {props.children}
        </ContextBreadcrumb.Provider>
    )
}