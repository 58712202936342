import {createContext} from 'react';

export const ContextAuth = createContext({
    menu: null,
    routes: null,
    token: null,
    user: null,
    login: () => {},
    logout: () => {},
    editUser: () => {}
})