import React from 'react';

import HeaderRow1 from "./HeaderRow1";
import HeaderRow2 from "./HeaderRow2";
import HeaderRow3 from "./HeaderRow3";
// import HeaderRow4 from "./HeaderRow4";

import {Navbar} from 'react-bootstrap';

import './Header.scss';

const Header = props => {

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <header id="header" className="sticky-top">
            <Navbar expand="md" variant="light" bg="light" className="flex-wrap pb-0 pt-md-4" collapseOnSelect>
                <HeaderRow1/>
                <Navbar.Collapse id="navbarCollapse" className="w-100 flex-wrap position-relative bg-black bg-opacity-4 shadow-sm">
                    <HeaderRow2 data={props?.data}/>
                    <HeaderRow3 data={props?.data?.menu}/>
                    {/*<HeaderRow4/>*/}
                </Navbar.Collapse>
            </Navbar>
        </header>
    )
}

export default Header;