import React, {useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';

import {ContextAuth} from '../../../common/context/context-auth';

import setNativeValue from '../../../common/util/set-native-value';
import showValidators from '../../../common/util/show-validators';
import {
    validatorMobile,
    validatorLength,
    validatorNotEmpty
} from '../../../common/util/validators';

import {useHttpClient} from '../../../common/hook/http';
import {useFrom} from '../../../common/hook/form';
import {useToast} from '../../../common/hook/toast';

import FormElement from '../../../common/component/FormElement/FormElement';
import CountDown from '../../../common/component/CountDown/CountDown';

import {Form, Button, Spinner, Col} from 'react-bootstrap';

import * as IconFi from 'react-icons/fi';

const LoginForm = props => {
    const auth = useContext(ContextAuth)
    const {callAPI} = useHttpClient()
    const {callToast} = useToast();
    const navigate = useNavigate()

    // ----------------------------------------------------------------------------------------------------- form submit

    const [formState1, inputHandler1] = useFrom({
        formLoginPhone: {
            value: '',
            isValid: false
        }
    }, false)

    const [formState2, inputHandler2] = useFrom({
        formLoginOtp: {
            value: '',
            isValid: false
        },
        formLoginOtp1: {
            value: '',
            isValid: false
        },
        formLoginOtp2: {
            value: '',
            isValid: false
        },
        formLoginOtp3: {
            value: '',
            isValid: false
        },
        formLoginOtp4: {
            value: '',
            isValid: false
        }
    }, false)

    const [spinnerShow, setSpinnerShow] = useState(false);

    const [loginStep, setLoginStep] = useState(1);

    const formSubmitHandler = async event => {
        event.preventDefault();

        let formState = loginStep === 1 ? formState1 : formState2;

        if (formState.isValid) {

            setSpinnerShow(true);

            let data = loginStep === 1 ? {phone: formState.inputs.formLoginPhone.value} : {
                phone: formState1.inputs.formLoginPhone.value,
                otp: formState.inputs.formLoginOtp.value
            }

            try {

                const responseData = await callAPI(
                    loginStep === 1 ? 'register' : 'login',
                    'POST',
                    JSON.stringify(data)
                );

                setSpinnerShow(false);

                if (responseData.message) {
                    callToast('secondary', null, null, responseData.message);
                } else {

                    if (loginStep === 1) {

                        // console.log(responseData.result['verifyCode']);
                        setResendCodeTimer(() => 120);
                        setLoginStep(() => 2);

                    } else {

                        let {accessToken, ...responseDataRest} = responseData.result

                        auth.login(null, accessToken, responseDataRest, true);

                        if (window.redirect) {

                            navigate(window.redirect)

                            let timeout = setTimeout(() => {

                                clearTimeout(timeout)

                                props.onHide('redirect')

                            }, 1000)

                        } else props.onHide()

                    }

                }

            } catch (e) {
                setSpinnerShow(false);
                console.error(e);
            }

        } else showValidators(formState.inputs)
    };

    // ------------------------------------------------------------------------------------------------------------- OTP

    const setOtpHandler = event => {

        let id = event.target.id[event.target.id.length - 1];

        if (event.type === 'keydown') {
            if (event.keyCode === 8 && event.target.value === '' && Number(id) > 1)
                document.querySelector(`#formLoginOtp${Number(id) - 1}`).focus()
        } else if (event.type === 'keyup') {
            if (event.target.value !== '' && Number(id) < 4) {
                if (!isNaN(event.target.value)) {
                    document.querySelector(`#formLoginOtp${Number(id) + 1}`).focus();
                }
            }
            setNativeValue('formLoginOtp', formState2.inputs.formLoginOtp1.value + formState2.inputs.formLoginOtp2.value + formState2.inputs.formLoginOtp3.value + formState2.inputs.formLoginOtp4.value);
        }

    }

    const [resetLoginStep2, setResetLoginStep2] = useState(false);

    const inputFocusHandler = event => {
        // این تابع با هر transition مثل hover دکمه ها اجرا می شود، بررسی گردد
        if (event.propertyName === "transform") {
            if (event.target.id === 'formLoginStep2' && !event.target.classList.contains('out')) {
                document.querySelector('#formLoginOtp1').focus();
            } else if (event.target.id === 'formLoginStep1' && !event.target.classList.contains('out')) {
                setResendCodeTimer(() => -1);
                setResetLoginStep2(() => true);
                document.querySelector('#formLoginPhone').focus();
                setResetLoginStep2(() => false);
                // let length = document.querySelector('#formLoginPhone').value.length
                // document.querySelector('#formLoginPhone').setSelectionRange(length, length);
                // document.querySelector('#formLoginPhone').scrollLeft = document.querySelector('#formLoginPhone').scrollWidth;
                document.querySelector('#formLoginPhone').setSelectionRange(11, 11);
            }
        }
    }

    const [resendCodeTimer, setResendCodeTimer] = useState(0);

    // ---------------------------------------------------------------------------------------------------------- action

    const countDownEndHandler = () => {
        setResendCodeTimer(() => 0);
    }

    const changeMobileNumberHandler = () => {
        setLoginStep(() => 1);
    }

    const resendCodeHandler = () => {

    }

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <>
            <Form id="formLoginStep1" onSubmit={formSubmitHandler} autoComplete="off" className={`d-flex flex-column position-absolute top-0 start-0 bottom-0 end-0${loginStep === 1 ? '' : ' out'}`} onTransitionEnd={inputFocusHandler}>
                <p className="mb-auto text-black text-opacity-54">کاربر گرامی، جهت دسترسی به خدمات و دریافت پیشنهادات متنوع وارد شوید:</p>
                <FormElement component="INPUT" id="formLoginPhone" name="phone" className="ltr text-left ls-1 fs-18px" label="شماره تلفن همراه"
                             validators={[validatorMobile()]} textInvalid="شماره تلفن همراه را وارد نمایید" onInput={inputHandler1} maxLength="11" digit inputMode="numeric"/>
                <Button type="submit" variant="primary" className="btn-loading">
                    <Spinner as="i" animation="border" size="sm" className={`border-1${spinnerShow ? ' opacity-100' : ''}`}/>
                    <span>ادامه</span>
                </Button>
            </Form>
            <Form id="formLoginStep2" onSubmit={formSubmitHandler} autoComplete="off" className={`d-flex flex-column${loginStep === 2 ? '' : ' out'}`} onTransitionEnd={inputFocusHandler}>
                <p className="mb-auto text-black text-opacity-54">کد اعتبارسنجی ارسال شده به شماره <span className="ls-half px-1">{formState1.inputs.formLoginPhone.value}</span> را وارد نمایید:</p>
                {
                    !resetLoginStep2 && (
                        <div className="position-relative">
                            <FormElement component="INPUT" id="formLoginOtp" name="otp"
                                         className="opacity-0"
                                         label="کد اعتبارسنجی"
                                         validators={[validatorLength([4, 4])]}
                                         textInvalid="کد اعتبارسنجی را وارد نمایید"
                                         onInput={inputHandler2} maxLength="4" digit/>
                            <div className="row g-2 ltr position-relative z-index-1 form-login-otp-wrapper">
                                <Col>
                                    <FormElement component="INPUT" id="formLoginOtp1" name="otp1"
                                                 className="text-center"
                                                 onInput={inputHandler2} maxLength="1" digit inputMode="numeric"
                                                 validators={[validatorNotEmpty()]}
                                                 onKeyUp={setOtpHandler} onKeyDown={setOtpHandler}/>
                                </Col>
                                <Col>
                                    <FormElement component="INPUT" id="formLoginOtp2" name="otp2"
                                                 className="text-center"
                                                 onInput={inputHandler2} maxLength="1" digit inputMode="numeric"
                                                 validators={[validatorNotEmpty()]}
                                                 onKeyUp={setOtpHandler} onKeyDown={setOtpHandler}/>
                                </Col>
                                <Col>
                                    <FormElement component="INPUT" id="formLoginOtp3" name="otp3"
                                                 className="text-center"
                                                 onInput={inputHandler2} maxLength="1" digit inputMode="numeric"
                                                 validators={[validatorNotEmpty()]}
                                                 onKeyUp={setOtpHandler} onKeyDown={setOtpHandler}/>
                                </Col>
                                <Col>
                                    <FormElement component="INPUT" id="formLoginOtp4" name="otp4"
                                                 className="text-center"
                                                 onInput={inputHandler2} maxLength="1" digit inputMode="numeric"
                                                 validators={[validatorNotEmpty()]}
                                                 onKeyUp={setOtpHandler} onKeyDown={setOtpHandler}/>
                                </Col>
                            </div>
                        </div>
                    )
                }
                <div className="mb-3 d-flex justify-content-between">
                    <Button variant="link" className="text-decoration-none px-0" onClick={changeMobileNumberHandler}><IconFi.FiChevronRight size={24} className="sw-1 me-1"/>تغییر شماره</Button>
                    <Button variant="link" className={`text-decoration-none ps-0 ${resendCodeTimer ? 'pe-1 ls-1 fs-5' : 'pe-0'}`} disabled={resendCodeTimer} onClick={resendCodeHandler}>
                        {
                            resendCodeTimer === 0 ? (
                                <>ارسال دوباره کد<IconFi.FiRotateCw size={24} className="sw-1 me-1 ms-2"/></>
                            ) : (
                                <CountDown timer={resendCodeTimer} onEnd={countDownEndHandler}/>
                            )
                        }
                    </Button>
                </div>
                <Button id="formLoginStep2SubmitBtn" type="submit" variant="primary" className="w-100 btn-loading">
                    <Spinner as="i" animation="border" size="sm" className={`border-1${spinnerShow ? ' opacity-100' : ''}`}/>
                    <span>ورود</span>
                </Button>
            </Form>
        </>
    )
}

export default LoginForm;
