import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import {useWindowSize} from '../../../common/hook/window-size'

import ModalMap from '../../modal/Map/Map';

import {Row, Col, Nav, Accordion} from 'react-bootstrap';

import * as IconFi from 'react-icons/fi';

const FooterRow2 = props => {
    const windowSize = useWindowSize()

    // ----------------------------------------------------------------------------------------------------- links array

    const linksArray = [
        {id: 1, title: 'اطلاعات بازدیدکنندگان', children: [
                {id: 2, title: 'خدمات', to: '/services'},
                {id: 3, title: 'نقشه میکا مال', onClick: () => setModalMapShow(true)}
            ]
        },
        {id: 10, title: 'درباره ما', children: [
                {id: 11, title: 'درباره میکا مال', to: '/about-us/about-micamall'},
                {id: 12, title: 'قوانین', to: '/about-us/rules'},
                {id: 13, title: 'سیاست حفظ حریم خصوصی', to: '/about-us/privacy-policy'},
                {id: 14, title: 'شرایط و ضوابط', to: '/about-us/terms-and-conditions'},
                {id: 15, title: 'جلوگیری از تقلب', to: '/about-us/anti-fraud-disclaimer'}
            ]
        },
        {id: 20, title: 'خدمات الکترونیک', children: [
                // {id: 21, title: 'خرید فروشگاه', to: 'e-services/real-estate/buy'},
                {id: 22, title: 'اجاره فروشگاه', to: 'e-services/real-estate/leasing'},
                // {id: 23, title: 'اجاره فضای تبلیغات', to: '#'},
                {id: 24, title: 'فرصت های شغلی', to: 'e-services/careers'}
            ]
        },
        {id: 30, title: 'ارتباط با ما', desc: props.data && props.data[0],
            children: props.data?.slice(1).map((item, index) => {
                if (item.includes('@')) return {id: 31 + index, title: item, href: `mailto:${item}`}
                else if (item.startsWith('+')) return {id: 31 + index, title: '+' + item.substring(1).match(/.{1,2}/g).join(' '), href: `tel:${item}`}
                else return {id: 31 + index, title: item, href: ''}
            }
        )}
    ]

    // ------------------------------------------------------------------------------------------------------- modal map

    const [modalMapShow, setModalMapShow] = useState(false);

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <>
            {
                windowSize[0] < 768 ? (
                    <Accordion flush className="mt-3 links">
                        {
                            linksArray.map((item, i) => {
                                return (
                                    <Accordion.Item eventKey={i} key={item.id}>
                                        <Accordion.Header as="h6">{item.title}<IconFi.FiChevronDown size={24} className="sw-1 ms-2 caret"/></Accordion.Header>
                                        <Accordion.Body className="px-0 pt-0 pb-3">
                                            {
                                                item.desc && (<p>{item.desc}</p>)
                                            }
                                            <Nav className="flex-column">
                                                {
                                                    item.children?.map(item => {
                                                        return item.to ? (
                                                            <Nav.Link as={Link} to={item.to} className="px-0 py-1 text-reset" key={item.id}>{item.title}</Nav.Link>
                                                        ) : item.onClick ? (
                                                            <Nav.Link className="px-0 py-1 text-reset" key={item.id} onClick={item.onClick}>{item.title}</Nav.Link>
                                                        ) : (
                                                            <Nav.Link href={item.href} className="px-0 py-1 text-reset" key={item.id}>{item.title}</Nav.Link>
                                                        )
                                                    })
                                                }
                                            </Nav>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            })
                        }
                    </Accordion>
                ) : (
                    <Row className="mt-5 links">
                        {
                            linksArray.map((item, i) => {
                                return (
                                    <Col md key={item.id}>
                                        <h6>{item.title}</h6>
                                        {
                                            item.desc && (<p>{item.desc}</p>)
                                        }
                                        <Nav className="flex-column">
                                            {
                                                item.children?.map(item => {
                                                    return item.to ? (
                                                        <Nav.Link as={Link} to={item.to} className="px-0 py-1 text-reset" key={item.id}>{item.title}</Nav.Link>
                                                    ) : item.onClick ? (
                                                        <Nav.Link className="px-0 py-1 text-reset" key={item.id} onClick={item.onClick}>{item.title}</Nav.Link>
                                                    ) : (
                                                        <Nav.Link href={item.href} className="px-0 py-1 text-reset" key={item.id}>{item.title}</Nav.Link>
                                                    )
                                                })
                                            }
                                        </Nav>
                                    </Col>
                                )
                            })
                        }
                        <Col md="auto">
                            <a referrerPolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=253090&amp;Code=98c6W3VzpheO1k6UTKKb">
                                <img referrerPolicy="origin" src="https://Trustseal.eNamad.ir/logo.aspx?id=253090&amp;Code=98c6W3VzpheO1k6UTKKb" alt="" style={{cursor: 'pointer'}} id="98c6W3VzpheO1k6UTKKb"/>
                            </a>
                        </Col>
                    </Row>
                )
            }
            <ModalMap show={modalMapShow} onHide={() => setModalMapShow(false)}/>
        </>
    )
}

export default FooterRow2;