import React, {createContext, useCallback, useState} from 'react';
import {ToastContainer, Toast} from 'react-bootstrap';

import * as IconFi from 'react-icons/fi';

const ContextToast = createContext({
    callToast: () => {}
});

export default ContextToast;

export const ContextToastProvider = props => {

    const [toasts, setToasts] = useState([])

    const callToast = useCallback((type, title, small, message, autoHide, offline) => {

        if (offline !== false) {

            setToasts(toasts => [...toasts, {
                type: type,
                title: title,
                small: small,
                message: message,
                autoHide: (autoHide || autoHide === false) ? autoHide : true,
                show: true,
                index: toasts.length > 0 ? Math.max.apply(Math, toasts.map(toast => {
                    return toast.index
                })) + 1 : 0,
                offline: offline
            }])

        } else setToasts(toasts => toasts.filter(({offline}) => offline !== true))

    }, [setToasts]);

    const hideToastHandler = index => {

        let tempToasts = [...toasts]

        tempToasts.find(tempToast => tempToast.index === index).show = false

        setToasts(() => tempToasts)

        setTimeout(() => {
            tempToasts = tempToasts.filter(tempToast => tempToast.index !== index);
            setToasts(tempToasts)
        }, 200)

    }

    return (
        <ContextToast.Provider value={{callToast: callToast}}>
            {props.children}
            <ToastContainer id="toastArea" position={'bottom-center'} className="position-fixed" style={toasts.length > 0 ? {padding: '.75rem .75rem 1.5rem .75rem'} : null}>
                {
                    toasts.map(item => {
                        return <Toast onClose={() => hideToastHandler(item.index)} show={item.show} autohide={item.autoHide} delay={7000} className={`position-relative text-white text-opacity-87 font-fa border-0 bg-${item.type}`} key={item.index}>
                            {
                                item.title && (
                                    <Toast.Header className="align-items-start justify-content-between px-3 border-bottom-0 bg-transparent text-white text-opacity-87" closeButton={false}>
                                        <span className="align-self-center fw-normal">{item.title}</span>
                                        <small>{item.small}</small>
                                        {/*<Icon.FiX size={24} className="ms-3 flex-shrink-0 close" onClick={() => hideToastHandler(item.index)}/>*/}
                                    </Toast.Header>
                                )
                            }
                            <Toast.Body className="align-items-start px-3 d-flex">
                                {
                                    item.offline && (
                                        <IconFi.FiWifiOff size={24} className="me-3 flex-shrink-0"/>
                                    )
                                }
                                {/*<span className="align-self-center">{item.message}</span>*/}
                                <span className="align-self-center" dangerouslySetInnerHTML={{__html: item.message}}/>
                                {/*{!item.title && <Icon.FiX size={24} className="ms-3 flex-shrink-0 close" onClick={() => hideToastHandler(item.index)}/>}*/}
                            </Toast.Body>
                        </Toast>
                    })
                }
            </ToastContainer>
        </ContextToast.Provider>
    )
}