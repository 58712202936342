import React, {useEffect} from 'react';
import {useToast} from '../../../common/hook/toast';

import './Main.scss'

const Main = props => {
    const {callToast} = useToast()

    // -----------------------------------------------------------------------------------------------------------------

    useEffect(() => {

        if (!navigator.onLine) callToast('secondary', null, null, 'اتصال به اینترنت قطع می باشد', false, true);

        const changeStatus = e => {
            if (e.type === 'offline') callToast('secondary', null, null, 'اتصال به اینترنت قطع می باشد', false, true);
            else callToast('secondary', null, null, 'اتصال به اینترنت برقرار شد', null, false);
        }

        window.addEventListener('online', changeStatus);
        window.addEventListener('offline', changeStatus);

        return () => {
            window.removeEventListener('online', changeStatus);
            window.removeEventListener('offline', changeStatus);
        };

    }, []);

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <main id="main" className="flex-grow-1">
            {props.children}
        </main>
    )
}

export default Main;