import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import {Button, Col} from 'react-bootstrap';

const Tags = props => {

    const [more, setMore] = useState(false)

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <>
            {
                props.tags.map((item, index) => {
                    return (
                        index < 5 ? (
                            <Col xs="auto" key={index}><Button as={Link} to={'#'} variant="outline-dark" size="sm">{item}</Button></Col>
                        ) : (
                            <React.Fragment key={index}>
                                {
                                    index === 5 && (
                                        <Col xs="auto" className={more ? 'd-none' : false} key={`more${index}`}>
                                            <Button variant="link" size="sm" className="text-reset text-decoration-none px-0 fs-6" onClick={() => setMore(true)}>ادامـه...</Button>
                                        </Col>
                                    )
                                }
                                <Col xs="auto" className={more ? false : 'd-none'} key={`invisible${index}`}>
                                    <Button as={Link} to={'#'} variant="outline-dark" size="sm">{item}</Button>
                                </Col>
                            </React.Fragment>
                        )
                    )
                })
            }
        </>
    )
}

export default Tags;