import React, {useCallback, useEffect} from 'react';

import {Button} from 'react-bootstrap';

import * as IconFi from 'react-icons/fi';

const SearchBtn = props => {

    const modalSearchShowHandler = () => {

        props.onShow()

        if(!document.querySelector('[aria-controls="navbarCollapse"]').classList.contains('collapsed'))
            document.querySelector('[aria-controls="navbarCollapse"]').click()
    }

    // -----------------------------------------------------------------------------------------------------------------

    const handleModalSearchHide = useCallback(e => {

        // چک شد که مودال هنگام باز شدن دوباره بسته نشود و پس از باز شدن آی دی به searchBtnX تغییر می یابد
        if (!e.target.closest('#searchBtn')) document.querySelector('#modalSearch [aria-label="Close"]').click()

    }, [])

    useEffect(() => {

        if (props.show) document.querySelector('#header').addEventListener('click', handleModalSearchHide);
        else document.querySelector('#header').removeEventListener('click', handleModalSearchHide);

    }, [props.show]);

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <Button id={props.show ? 'searchBtnX' : 'searchBtn'} type="button" variant="link" className="text-reset d-flex align-items-center me-2 border-0 p-2 shadow-none nav-link btn-icon" onClick={() => props.show ? undefined : modalSearchShowHandler()}>
            {
                props.show ? (
                    <IconFi.FiX size={24} className="sw-1 flip-h"/>
                ) : (
                    <IconFi.FiSearch size={24} className="sw-1 flip-h"/>
                )
            }
        </Button>
    )
}

export default SearchBtn;