import React from 'react';
import moment from 'jalali-moment';

import GlobalConst from '../../../common/global-const';

import {Container, Row, Col, Nav} from 'react-bootstrap';

import {ReactComponent as ImgTelegram} from '../../../asset/img/icon/telegram.svg';
import * as IconFi from 'react-icons/fi';
import * as IconGr from 'react-icons/gr';

const FooterRow3 = props => {

    const icons = [
        {name: 'instagram', icon: <IconFi.FiInstagram size={24}/>},
        {name: 'twitter', icon: <IconGr.GrTwitter size={24}/>},
        {name: 'facebook', icon: <IconGr.GrFacebookOption size={24}/>},
        {name: 'youtube', icon: <IconGr.GrFacebookOption size={24}/>},
        {name: 't.me', icon: <ImgTelegram/>}
    ]

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <div className="bg-accent py-3">
            <Container fluid="lg">
                <Row className="align-items-center justify-content-between">
                    <Col md={8} className="fs-9 text-white ls-half ws-n1 text-center text-md-start">
                        © {moment(new Date(), 'YYYY').locale('fa').format('YYYY')} کلیه حقوق محفوظ و متعلق به {GlobalConst.ownerCompany} می باشد.
                    </Col>
                    <Col md="auto" className="order-first order-md-last mb-2 mb-md-0">
                        <Nav className="justify-content-center justify-content-lg-end lh-1 mx-n1 text-end">
                            {
                                props.data ? (
                                    props.data.map((item, index) => {
                                        return icons.find(({name}) => item.includes(name))?.icon ? (
                                            <Nav.Link href={item} target="_blank" className="p-2 mx-1 text-white" key={index}>{icons.find(({name}) => item.includes(name)).icon}</Nav.Link>
                                        ) : (
                                            <></>
                                        )
                                    })
                                ) : (
                                    <></>
                                )
                            }
                        </Nav>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FooterRow3;