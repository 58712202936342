import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, Link, useLocation} from 'react-router-dom';

import {useHttpClient} from '../../../common/hook/http';
import {useFrom} from '../../../common/hook/form';
import {useToast} from '../../../common/hook/toast';

import setNativeValue from '../../../common/util/set-native-value';

import FormElement from '../../../common/component/FormElement/FormElement';

import GlobalConst from '../../../common/global-const';

import {Modal, Form, Row, Col, Tab, Button, Nav} from 'react-bootstrap';

import * as IconFi from 'react-icons/fi';

import './Search.scss';

const ModalSearch = props => {
    const {callAPI} = useHttpClient()
    const navigate = useNavigate()
    const location = useLocation()
    const {callToast} = useToast();

    // ------------------------------------------------------------------------------------------------ on show and hide

    const handlePopstate = useCallback(res => {

        if (document.querySelectorAll('.modal').length < 2)
            window.removeEventListener('popstate', handlePopstate)

        props.onHide()

    },[])

    const onShowHandler = () => {

        document.querySelector('#formSearchKeyword').focus()

        document.getElementById('modalSearch').style.paddingRight = document.querySelector('body').style.paddingRight

        navigate(`#modalSearch`)
        window.addEventListener('popstate',  handlePopstate);

    }

    const onHideHandler = () => {

        navigate(-1)

    }

    const modalLinkHandler = e => {

        e.preventDefault();

        navigate(location.pathname, {replace: true})
        navigate(e.currentTarget.getAttribute('href'))
        navigate(`${e.currentTarget.getAttribute('href')}${location.hash}`)

        onHideHandler()

    }

    // ----------------------------------------------------------------------------------------------------- submit form

    const [result, setResult] = useState(null)

    const [formState, inputHandler] = useFrom({
        formSearchKeyword: {
            value: '',
            isValid: false
        }
    }, false);

    const formSubmitHandler = async e => {

        if (e?.type) e.preventDefault();

        // طول مقدار اینجا هم چک شد، شاید کلید enter زده شد
        if (formState.inputs.formSearchKeyword.value.length > 2) {

            const values = {keyword: formState.inputs.formSearchKeyword.value, type: ['all'], limit: 9999}

            try {
                const responseData = await callAPI(
                    'search',
                    'POST',
                    JSON.stringify(values)
                )

                // console.log(responseData)

                if (responseData.message) {
                    callToast('secondary', null, null, responseData.message);
                } else {

                    setResult(responseData.result)

                }


            } catch (err) {
                callToast('danger', null, null, 'خطا رخ داد');
            }

        }

    }

    useEffect(() => {

        if (result) setResult(null)

        let timeout;

        if (formState.inputs.formSearchKeyword.value.length > 2) {

            timeout = setTimeout(() => {

                formSubmitHandler()

                clearTimeout(timeout)

            }, 2000)

        }

        return () => clearTimeout(timeout)

    }, [formState.inputs.formSearchKeyword.value]);

    // -----------------------------------------------------------------------------------------------------------------

    const searchResetHandler = e => {

        e.stopPropagation()

        setNativeValue('formSearchKeyword', '')

    }

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <Modal id="modalSearch" {...props} fullscreen backdrop={false} onHide={onHideHandler} onShow={onShowHandler} className="ltr bg-white h-auto bottom-0 modal-search" dialogClassName="rtl h-auto" contentClassName="container-lg px-0">
            <Modal.Header className="p-0">
                <Button aria-label="Close" className="position-absolute invisible" onClick={onHideHandler}/>
            </Modal.Header>
            <Modal.Body className="overflow-visible pb-5">
                <Form id="formSearch" onSubmit={formSubmitHandler} autoComplete="off">
                    <Row>
                        <Col sm={12}>
                            <FormElement component="INPUT" type="text" id="formSearchKeyword" name="term" placeholder="جستجوی میکا مال" className="rounded-0 ps-0 border-top-0 border-start-0 border-end-0" onInput={inputHandler}
                                         append={formState.inputs.formSearchKeyword.value.length > 2 ? <Button variant="link" className="position-absolute bottom-0 end-0 btn-icon" onClick={searchResetHandler}><IconFi.FiTrash size={24} className="sw-1"/></Button> : <IconFi.FiSearch size={24} className="sw-1 flip-h text-black text-opacity-38"/>}/>
                        </Col>
                    </Row>
                </Form>
                {
                    formState.inputs.formSearchKeyword.value.length > 2 ? (
                        result ? (
                            <Tab.Container id="searchTab" defaultActiveKey="tabSearch1">
                                <Nav as="ul" variant="tabs" className="flex-row pt-sm-3 hide-scrollbar">
                                    <Nav.Item as="li" role="presentation">
                                        <Nav.Link as="button" eventKey="tabSearch1" className="me-2 me-md-3">همه<span className="ls-1 fs-6 ms-1">({result.shops.length + result.offers.length + result.news.length})</span></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li" role="presentation">
                                        <Nav.Link as="button" eventKey="tabSearch2" className="mx-2 mx-md-3">خرید<span className="ls-1 fs-6 ms-1">({result.shops.length})</span></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li" role="presentation">
                                        <Nav.Link as="button" eventKey="tabSearch3" className="mx-2 mx-md-3">تخفیف ها<span className="ls-1 fs-6 ms-1">({result.offers.length})</span></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li" role="presentation">
                                        <Nav.Link as="button" eventKey="tabSearch4" className="mx-2 mx-md-3">رویدادها و جایزه ها<span className="ls-1 fs-6 ms-1">(0)</span></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li" role="presentation">
                                        <Nav.Link as="button" eventKey="tabSearch5" className="ms-2 ms-md-3">خبرها و تازه ها<span className="ls-1 fs-6 ms-1">({result.news.length})</span></Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <hr className="mt-0"/>
                                <div className="h3 fw-normal text-center mb-0 mt-3 pt-4 position-relative">نتیجه جستجو برای "{formState.inputs.formSearchKeyword.value}"</div>
                                <Tab.Content>
                                    <Tab.Pane eventKey="tabSearch1">
                                        <Row className="g-3">
                                            <Col xs={12} className="fs-4 mb-n2 pt-4">خرید<span className="ms-1 ls-1">({result.shops.length})</span></Col>
                                            {
                                                result.shops.map(item => {
                                                    return (
                                                        <Col sm={6} md={4} key={item.id}>
                                                            <Link to={`/${item.type === '1' ? 'shop' : item.type === '2' ? 'dine' : 'entertain'}/${item.id}`} className="text-reset text-decoration-none ratio ratio-21x9 bg-black bg-opacity-4 d-block"
                                                                  onClick={modalLinkHandler}>
                                                            <span className="d-flex flex-column justify-content-around align-items-center px-3">
                                                                <span className="ls-half ws-n1 h5 mb-0 mt-3 fw-light text-truncate mw-100">{item.title}</span>
                                                                <span className="text-black text-opacity-54">طبقه {item.floor}</span>
                                                            </span>
                                                            </Link>
                                                        </Col>
                                                    )
                                                })
                                            }
                                            <Col xs={12} className="fs-4 mb-n2 pt-4">تخفیف ها<span className="ms-1 ls-1">({result.offers.length})</span></Col>
                                            {
                                                result.offers.map(item => {
                                                    return (
                                                        <Col sm={6} md={4} key={item.id}>
                                                            <Link to={`/offers/${item.id}`} state={{offer: item}} className="text-reset text-decoration-none d-flex flex-column" onClick={modalLinkHandler}>
                                                                <figure className="bg-black bg-opacity-4 ratio ratio-16x9 mb-2 filter-warm">
                                                                    <span className="filter-level"/>
                                                                    <img src={`${GlobalConst.cdn}${item.photos[0]?.path}`} className="w-100 h-00 object-fit-cover"/>
                                                                </figure>
                                                                <span className="text-black text-opacity-54 fs-5 ls-1 lh-1 pt-2 pb-1">{item.start_at}<span className="ls-normal fs-6 px-1"> تا </span>{item.end_at}</span>
                                                                <span className="fw-500 ls-half ws-n1">{item.shopName}</span>
                                                                <span>{item.title}</span>
                                                            </Link>
                                                        </Col>
                                                    )
                                                })
                                            }
                                            <Col xs={12} className="fs-4 mb-n2 pt-4">رویدادها و جایزه ها<span className="ms-1 ls-1">(0)</span></Col>
                                            <Col xs={12} className="fs-4 mb-n2 pt-4">خبرها و تازه ها<span className="ms-1 ls-1">({result.news.length})</span></Col>
                                            {
                                                result.news.map(item => {
                                                    return (
                                                        <Col sm={6} md={4} key={item.news.id}>
                                                            <Link to={`/news/${item.news.id}`} state={{news: item}} className="d-block" onClick={modalLinkHandler}>
                                                                <figure className="mb-0 ratio ratio-16x9 bg-black bg-opacity-4 filter-warm">
                                                                    <span className="filter-level"/>
                                                                    <img src={`${GlobalConst.cdn}${item.cover.path}`} className="w-100 h-100 object-fit-cover"/>
                                                                </figure>
                                                            </Link>
                                                            <div className="mt-2">
                                                                <div className="text-black text-opacity-54">{item.news.created_at}</div>
                                                                <div className="text-truncate fw-500 ls-half ws-n1">{item.newsCategory.title}</div>
                                                                <Link to={`/news/${item.news.id}`} state={{news: item}} className="text-truncate text-decoration-none text-reset d-block" onClick={modalLinkHandler}>{item.news.title}</Link>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabSearch2">
                                        <Row className="g-3">
                                            <Col xs={12} className="fs-4 mb-n2 pt-4">خرید<span className="ms-1 ls-1">({result.shops.length})</span></Col>
                                            {
                                                result.shops.map(item => {
                                                    return (
                                                        <Col sm={6} md={4} key={item.id}>
                                                            <Link to={`/${item.type === '1' ? 'shop' : item.type === '2' ? 'dine' : 'entertain'}/${item.id}`} className="text-reset text-decoration-none ratio ratio-21x9 bg-black bg-opacity-4 d-block"
                                                                  onClick={modalLinkHandler}>
                                                            <span className="d-flex flex-column justify-content-around align-items-center px-3">
                                                                <span className="ls-half ws-n1 h5 mb-0 mt-3 fw-light text-truncate mw-100">{item.title}</span>
                                                                <span className="text-black text-opacity-54">طبقه {item.floor}</span>
                                                            </span>
                                                            </Link>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabSearch3">
                                        <Row className="g-3">
                                            <Col xs={12} className="fs-4 mb-n2 pt-4">تخفیف ها<span className="ms-1 ls-1">({result.offers.length})</span></Col>
                                            {
                                                result.offers.map(item => {
                                                    return (
                                                        <Col sm={6} md={4} key={item.id}>
                                                            <Link to={`/offers/${item.id}`} state={{offer: item}} className="text-reset text-decoration-none d-flex flex-column" onClick={modalLinkHandler}>
                                                                <figure className="bg-black bg-opacity-4 ratio ratio-16x9 mb-2 filter-warm">
                                                                    <span className="filter-level"/>
                                                                    <img src={`${GlobalConst.cdn}${item.photos[0]?.path}`} className="w-100 h-00 object-fit-cover"/>
                                                                </figure>
                                                                <span className="text-black text-opacity-54 fs-5 ls-1 lh-1 pt-2 pb-1">{item.start_at}<span className="ls-normal fs-6 px-1"> تا </span>{item.end_at}</span>
                                                                <span className="fw-500 ls-half ws-n1">{item.shopName}</span>
                                                                <span>{item.title}</span>
                                                            </Link>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabSearch4">
                                        <Row className="g-3">
                                            <Col xs={12} className="fs-4 mb-n2 pt-4">رویدادها<span className="ms-1 ls-1">(0)</span></Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabSearch5">
                                        <Row className="g-3">
                                            <Col xs={12} className="fs-4 mb-n2 pt-4">رویدادها و جایزه ها<span className="ms-1 ls-1">({result.news.length})</span></Col>
                                            {
                                                result.news.map(item => {
                                                    return (
                                                        <Col sm={6} md={4} key={item.news.id}>
                                                            <Link to={`/news/${item.news.id}`} state={{news: item}} className="d-block" onClick={modalLinkHandler}>
                                                                <figure className="mb-0 ratio ratio-16x9 bg-black bg-opacity-4 filter-warm">
                                                                    <span className="filter-level"/>
                                                                    <img src={`${GlobalConst.cdn}${item.cover.path}`} className="w-100 h-100 object-fit-cover"/>
                                                                </figure>
                                                            </Link>
                                                            <div className="mt-2">
                                                                <div className="text-black text-opacity-54">{item.news.created_at}</div>
                                                                <div className="text-truncate fw-500 ls-half ws-n1">{item.newsCategory.title}</div>
                                                                <Link to={`/news/${item.news.id}`} state={{news: item}} className="text-truncate text-decoration-none text-reset d-block" onClick={modalLinkHandler}>{item.news.title}</Link>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        ) : (
                            <div className="d-flex flex-column align-items-center py-5 my-5 searching">
                                <IconFi.FiSearch size={60} className="mb-3"/>
                                <span className="text-secondary fs-5">در حال جستجو</span>
                            </div>
                        )
                    ) : (
                        <Row className="pt-4 gx-3 gy-4 featured-search">
                            <Col xs={12} className="fs-3 text-center">جستجوی ویژه</Col>
                            <Col sm={6} md={4}>
                                <Link to="/dine" className="text-reset text-decoration-none d-flex flex-column" onClick={modalLinkHandler}>
                                    <figure className="bg-black bg-opacity-4 ratio ratio-16x9 mb-2 filter-warm">
                                        <span className="filter-level"/>
                                        <img src="../img/page/dine/box/3.jpg" className="h-100 w-100 object-fit-cover"/>
                                    </figure>
                                    <span className="text-truncate fw-500 ls-half ws-n1">گیاه خواری</span>
                                    <span className="text-truncate">به خواسته شما توجه داریم</span>
                                </Link>
                            </Col>
                            <Col sm={6} md={4}>
                                <Link to="/dine" className="text-reset text-decoration-none d-flex flex-column" onClick={modalLinkHandler}>
                                    <figure className="bg-black bg-opacity-4 ratio ratio-16x9 mb-2 filter-warm">
                                        <span className="filter-level"/>
                                        <img src="../img/page/dine/box/4.jpg" className="h-100 w-100 object-fit-cover"/>
                                    </figure>
                                    <span className="text-truncate fw-500 ls-half ws-n1">کافه بام</span>
                                    <span className="text-truncate">رفع خستگی</span>
                                </Link>
                            </Col>
                            <Col sm={6} md={4}>
                                <Link to="/entertain/4" className="text-reset text-decoration-none d-flex flex-column" onClick={modalLinkHandler}>
                                    <figure className="bg-black bg-opacity-4 ratio ratio-16x9 mb-2 filter-warm">
                                        <span className="filter-level"/>
                                        <img src="../img/page/entertain/box/2.jpg" className="h-100 w-100 object-fit-cover"/>
                                    </figure>
                                    <span className="text-truncate fw-500 ls-half ws-n1">تله کابین میکا مال</span>
                                    <span className="text-truncate">نمایی نو از سواحل زیبای کیش</span>
                                </Link>
                            </Col>
                            <Col sm={6} md={4}>
                                <Link to="/shop" className="text-reset text-decoration-none d-flex flex-column" onClick={modalLinkHandler}>
                                    <figure className="bg-black bg-opacity-4 ratio ratio-16x9 mb-2 filter-warm">
                                        <span className="filter-level"/>
                                        <img src="../img/page/shop/box/5.jpg" className="h-100 w-100 object-fit-cover"/>
                                    </figure>
                                    <span className="text-truncate fw-500 ls-half ws-n1">Orlebar Brown</span>
                                    <span className="text-truncate">کالکشن جدید پوشاک زندگی جزیره ای</span>
                                </Link>
                            </Col>
                            <Col sm={6} md={4}>
                                <Link to="/shop" className="text-reset text-decoration-none d-flex flex-column" onClick={modalLinkHandler}>
                                    <figure className="bg-black bg-opacity-4 ratio ratio-16x9 mb-2 filter-warm">
                                        <span className="filter-level"/>
                                        <img src="../img/page/shop/box/2.jpg" className="h-100 w-100 object-fit-cover"/>
                                    </figure>
                                    <span className="text-truncate fw-500 ls-half ws-n1">Henry Jacques</span>
                                    <span className="text-truncate">عطرهای سفارشی ویژه تابستان</span>
                                </Link>
                            </Col>
                        </Row>
                    )
                }
            </Modal.Body>
        </Modal>
    )
}

export default ModalSearch;