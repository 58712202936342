import React, {createContext, useCallback, useState} from 'react';

import DatePicker from 'react-mobile-datepicker';
import moment from 'jalali-moment';

import setNativeValue from '../util/set-native-value';

const ContextMobileDatePicker = createContext({
    callMobileDatePicker: () => {
    }
});

export default ContextMobileDatePicker;

const datePickerMonths = {
    '1': 'فروردین',
    '2': 'اردیبهشت',
    '3': 'خرداد',
    '4': 'تیر',
    '5': 'مرداد',
    '6': 'شهریور',
    '7': 'مهر',
    '8': 'آبان',
    '9': 'آذر',
    '10': 'دی',
    '11': 'بهمن',
    '12': 'اسفند',
};

const datePickerInitial = {
    'year': {
        format: 'YYYY',
        caption: 'سال',
        step: 1,
    },
    'month': {
        format: value => datePickerMonths[value.getMonth() + 1],
        caption: 'ماه',
        step: 1,
    },
    'date': {
        format: 'D',
        caption: 'روز',
        step: 1,
    }
}

const timePickerInitial = {
    'hour': {
        format: 'hh',
        caption: 'ساعت',
        step: 1,
    },
    'minute': {
        format: 'mm',
        caption: 'دقیقه',
        step: 1,
    }
}

export const ContextMobileDatePickerProvider = props => {

    const [type, setType] = useState('date')

    const dateCurrent = moment(new Date().getFullYear() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getDate(), 'YYYY/M/D').locale('fa').format('YYYY/M/D')
    const dateMin = moment((new Date().getFullYear() - 100) + '/' + (new Date().getMonth() + 1) + '/' + new Date().getDate(), 'YYYY/M/D').locale('fa').format('YYYY/M/D')
    const dateMax = moment((new Date().getFullYear() + 100) + '/' + (new Date().getMonth() + 1) + '/' + new Date().getDate(), 'YYYY/M/D').locale('fa').format('YYYY/M/D')

    const [datePicker, setDatePicker] = useState({
        id: null,
        value: new Date(dateCurrent),
        min: new Date(dateMin),
        max: new Date(dateMax),
        isOpen: false
    })

    const [timePicker, setTimePicker] = useState({
        id: null,
        value: new Date(dateCurrent),
        isOpen: false
    })

    const callMobileDatePicker = useCallback((id, value, min, max, time) => {

        let minTemp
        let maxTemp
        let currentTemp

        if (min) {
            if (Date.parse(min)) {

                if (time) minTemp = min
                else minTemp = moment(min.getFullYear() + '/' + (min.getMonth() + 1) + '/' + min.getDate(), 'YYYY/M/D').format('jYYYY/jM/jD')

            } else {

                if (time) minTemp = min
                else minTemp = moment(min, 'jYYYY/jM/jD').format('jYYYY/jM/jD')

            }
        } else minTemp = time ? moment(new Date(), 'jYYYY/jMM/jDD hh:mm:ss').format('jYYYY/jM/jD hh:mm:ss') : dateMin

        if (max) {
            if (Date.parse(max)) {

                if (time) maxTemp = max
                else maxTemp = moment(max.getFullYear() + '/' + (max.getMonth() + 1) + '/' + max.getDate(), 'YYYY/M/D').format('jYYYY/jM/jD')

            } else {

                if (time) maxTemp = max
                else maxTemp = moment(max, 'jYYYY/jM/jD').format('jYYYY/jM/jD')

            }
        } else maxTemp = time ? moment(new Date(), 'jYYYY/jMM/jDD hh:mm:ss').format('jYYYY/jM/jD hh:mm:ss') : dateMax

        if (value !== '') {

            if(time) currentTemp = moment(value, 'YYYY/MM/DD hh:mm:ss').format('jYYYY/jM/jD hh:mm:ss')
            else currentTemp = moment(value, 'jYYYY/jMM/jDD').format('jYYYY/jM/jD')

        } else currentTemp = time ? min ? min : moment(new Date()).format('jYYYY/jM/jD hh:mm:ss') : dateCurrent

        // console.log(minTemp)
        // console.log(maxTemp)
        // console.log(currentTemp)

        if (time) {

            setType('time')

            setTimePicker(() => {
                return {
                    id: id,
                    value: new Date(currentTemp),
                    min: new Date(minTemp),
                    max: new Date(maxTemp),
                    isOpen: true
                }
            })

        } else {

            setType('date')

            // let dateMinTemp
            // let dateMaxTemp
            // let dateCurrentTemp
            //
            // if (min) {
            //     if (Date.parse(min)) {
            //         dateMinTemp = moment(min.getFullYear() + '/' + (min.getMonth() + 1) + '/' + min.getDate(), 'YYYY/M/D').format('jYYYY/jM/jD')
            //     } else {
            //         dateMinTemp = moment(min, 'jYYYY/jM/jD').format('jYYYY/jM/jD')
            //     }
            // } else dateMinTemp = dateMin
            //
            // if (max) {
            //     if (Date.parse(max)) {
            //         dateMaxTemp = moment(max.getFullYear() + '/' + (max.getMonth() + 1) + '/' + max.getDate(), 'YYYY/M/D').format('jYYYY/jM/jD')
            //     } else {
            //         dateMaxTemp = moment(max, 'jYYYY/jM/jD').format('jYYYY/jM/jD')
            //     }
            // } else dateMaxTemp = dateMax
            //
            // if (value !== '') dateCurrentTemp = moment(value, 'jYYYY/jMM/jDD').format('jYYYY/jM/jD')
            // else dateCurrentTemp = dateCurrent

            setDatePicker(() => {
                return {
                    id: id,
                    value: new Date(currentTemp),
                    min: new Date(minTemp),
                    max: new Date(maxTemp),
                    isOpen: true
                }
            })
        }

    }, [setDatePicker]);

    const dateTimePickerSelectHandler = selected => {

        if (type === 'time') {

            setNativeValue(timePicker.id, [selected.getHours(), ('0' + (selected.getMinutes())).slice(-2), ('00')].join(':'))
            // setNativeValue(timePicker.id, [selected.getHours(), ('0' + (selected.getMinutes())).slice(-2)].join(':'))
            setTimePicker({...datePicker, isOpen: false})

        } else {

            setNativeValue(datePicker.id, [selected.getFullYear(), ('0' + (selected.getMonth() + 1)).slice(-2), ('0' + selected.getDate()).slice(-2)].join('/'))
            setDatePicker({...datePicker, isOpen: false})

        }

    }

    const dateTimePickerCancelHandler = () => {

        if (type === 'time') {

            setTimePicker(() => {
                return {...timePicker, isOpen: false}
            })

        } else {

            setDatePicker(() => {
                return {...datePicker, isOpen: false}
            })

        }

    }

    // const datePickerModalHandler = event => {
    //     if (event.target.classList.contains('datepicker-modal'))
    //         setDatePicker({...datePicker, isOpen: false})
    // }

    // useEffect(() => {
    //
    //     const mobileDatePickerWheel = event => {
    //
    //         let $ul = event.currentTarget.querySelector('ul')
    //
    //         let y = Number($ul.style.transform.split('(')[1].split(')')[0].replace('px', ''))
    //
    //         if (y <= 0 && y >= -360)
    //             $ul.style.transform = `translateY(${event.deltaY < 0 ? y === 0 ? 0 : y + 40 : y === -360 ? -360 : y - 40}px)`
    //
    //         // console.log(event.currentTarget)
    //         console.log(moment('1399/1/1', 'jYYYY/jMM/jDD').format('jYYYY/jM/jD'))
    //         setDatePicker(() => {
    //             return {
    //                 ...datePicker,
    //                 isOpen: true,
    //                 value: new Date(moment('1398/1/1', 'jYYYY/jMM/jDD').format('jYYYY/jM/jD'))
    //             }
    //         })
    //
    //     }
    //
    //     document.querySelectorAll('.datepicker-viewport').forEach(item => {
    //         item.addEventListener('wheel', mobileDatePickerWheel)
    //     })
    //
    //     return () => document.querySelectorAll('.datepicker-viewport').forEach(item => {
    //         item.removeEventListener('wheel', mobileDatePickerWheel)
    //     })
    //
    // }, [])

    return (
        <ContextMobileDatePicker.Provider value={{callMobileDatePicker: callMobileDatePicker}}>
            {props.children}
            {
                type === 'time' ? (
                    <DatePicker value={timePicker.value} isOpen={timePicker.isOpen} min={datePicker.min}
                                max={datePicker.max}
                                confirmText="انتخاب" cancelText="انصراف" showCaption={true}
                                dateConfig={timePickerInitial}
                                onSelect={dateTimePickerSelectHandler}
                                onCancel={dateTimePickerCancelHandler}
                                headerFormat={'hh:mm'}/>
                ) : (
                    <DatePicker value={datePicker.value} isOpen={datePicker.isOpen} min={datePicker.min}
                                max={datePicker.max}
                                confirmText="انتخاب" cancelText="انصراف" showCaption={true}
                                dateConfig={datePickerInitial}
                                onSelect={dateTimePickerSelectHandler}
                                onCancel={dateTimePickerCancelHandler}/>
                )
            }
        </ContextMobileDatePicker.Provider>
    )
}