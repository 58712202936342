import React from 'react';
import {Link} from 'react-router-dom';

import {Container, Nav} from 'react-bootstrap';

const HeaderRow2 = props => {

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <Nav className="w-100 bg-primary py-3 py-md-0">
            <Container fluid="lg" className="d-md-flex justify-content-md-center align-items-md-center">
                {
                    props?.data ? (
                        props.data.slice(props.data.length - 3 , props.data.length).map(item => {
                            return (
                                item.title && (
                                    <Nav.Link as={Link} to={item.link} eventKey={item.id} className="d-flex align-items-center p-0 text-white" key={item.id}>{item.title}</Nav.Link>
                                )
                            )
                        })
                    ) : (
                        <></>
                    )
                }
            </Container>
        </Nav>
    )
}

export default HeaderRow2;