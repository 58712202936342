import React, {useReducer, useState, useEffect} from 'react';

import {useHttpClient} from '../../hook/http';
import {useWindowSize} from '../../hook/window-size';

import {utilValidate} from '../../util/validators';
import currency from '../../util/currency'
import cardNumber from '../../util/card-number';

import {Form, InputGroup, Button, Spinner} from 'react-bootstrap';

import Select, {components} from 'react-select';
import AsyncSelect from 'react-select/async';

import * as IconFi from 'react-icons/fi';

const reducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE':
            return {
                ...state,
                value: action.val,
                isValid: action.validators ? utilValidate(action.val, action.validators) : true
            }
        case 'BLUR': {
            return {
                ...state,
                isBlurred: true
            }
        }
        case 'ASYNC_SELECT': {
            return {
                ...state,
                asyncSelect: action.asyncSelect,
            }
        }
        default:
            return state
    }
}

const FormElement = props => {
    const {callAPI} = useHttpClient()

    const [state, dispatch] = useReducer(reducer, {
        value: props.component === 'CHECKBOX' || props.component === 'SWITCH' ? props.defaultChecked : props.value ? props.value : '',
        isValid: props.validators ? !!props.validators.find(({type}) => type === 'NOT_REQUIRED') : true,
        isBlurred: false,
        asyncSelect: props.asyncSelectData && props.asyncSelectData[0]
    })

    const changeHandler = event => {

        let val;

        if (props.component === 'CHECKBOX' || props.component === 'SWITCH') val = event.target.checked
        else if (props.component === 'FILE') {

            if (event.target.files.length) {

                let valTemp = event.target.files
                // مقدار value استاندارد جهت نمایش در input ذخیره شد
                valTemp[0].val = event.target.value
                val = {fileList: valTemp}

            } else val = ''

        } else if (props.component === 'SELECT') {

            // val = event
            // val = event.value

            if (event && (event.value || event.value === '')) val = event.value
            else val = event

        } else {

            if (props['digit']) {

                let tempVal = event.target.value.replace(/[۰۱۲۳۴۵۶۷۸۹]/g, n => n.charCodeAt(0) - 1776).replace(/[٠١٢٣٤٥٦٧٨٩]/g, n => n.charCodeAt(0) - 1632);

                if (props.currency) tempVal = currency(tempVal)
                if (props.cardNumber) tempVal = cardNumber(tempVal)

                if (isNaN(Number(tempVal))) {

                    if (props.currency) {

                        if (!tempVal.match(/^[0-9/]+$/)) return;
                        val = tempVal;

                    } else if (props.cardNumber) {

                        if (!tempVal.match(/^[0-9-]+$/)) return;
                        val = tempVal;

                    } else return;

                } else val = tempVal;

            } else val = event.target.value;

        }

        dispatch({
            type: 'CHANGE',
            // val: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
            val: val,
            validators: props.validators
        })

    }

    const blurHandler = () => {
        dispatch({
            type: 'BLUR'
        })
    }

    // برای ریست کردن select
    useEffect(() => {

        if (props.clear && props.component === 'SELECT') {

            changeHandler(null)

        }

    }, [props.clear])

    const {id, onInput} = props;
    const {value, isValid} = state;

    useEffect(() => {
        onInput(id, value, isValid)
    }, [id, value, isValid, onInput])

    // ------------------------------------------------------------------------------------------------------ edit value

    // زمانی اتفاق می افتد که فرم در حالت ویرایش باشد
    useEffect(() => {

        if (props.editValue) {

            dispatch({
                type: 'CHANGE',
                val: props.editValue,
                validators: props.validators
            })

            // if (!state.value && state.value !== false) {
            //     if (props.component === 'SWITCH' && props.editValue !== undefined) {
            //         dispatch({
            //             type: 'CHANGE',
            //             val: props.editValue,
            //             validators: props.validators
            //         })
            //         // } else if (props.editValue || props.editValue === '') {
            //     } else if (props.editValue) {
            //         dispatch({
            //             type: 'CHANGE',
            //             val: props.editValue,
            //             validators: props.validators
            //         })
            //     }
            //
            // }

        }

        // }, [props.editValue, props.validators])
    // }, [props.editValue])
    }, [])

    // ------------------------------------------------------------------------------------------------- toggle password

    const [togglePasswordType, setTogglePasswordType] = useState('password');

    const togglePasswordTypeHandler = event => {
        if (event.type === 'mouseleave') {
            setTogglePasswordType(() => 'password');
            event.currentTarget.classList.remove('text-primary');
            event.currentTarget.classList.add('text-black');
            event.currentTarget.classList.add('text-opacity-54');
        } else if (event.type === 'mousedown' || (event.type === 'mouseup' && togglePasswordType !== 'password')) {
            setTogglePasswordType(togglePasswordType => togglePasswordType === 'password' ? 'text' : 'password');
            event.currentTarget.classList.toggle('text-primary');
            event.currentTarget.classList.toggle('text-black');
            event.currentTarget.classList.toggle('text-opacity-54');
        }
    };

    // ---------------------------------------------------------------------------------------------------- react select

    let windowWidth = useWindowSize()[0];

    const ReactSelectDropdownIndicator = propsItself => {
        return (
            <components.DropdownIndicator {...propsItself}>{props.searchIcon ? (<IconFi.FiSearch size={24} className="sw-1 flip-h search"/>) : (<IconFi.FiChevronDown size={24} className="sw-1"/>)}</components.DropdownIndicator>
        )
    }

    const ReactSelectClearIndicator = propsItself => {
        return (
            <components.ClearIndicator {...propsItself}><IconFi.FiTrash size={24} className="sw-1"/></components.ClearIndicator>
        )
    }

    const ReactSelectLoadingIndicator = () => {
        // return (<div {...propsItself}><Spinner as="span" animation="border" size="sm"/></div>)
        return (<div><Spinner as="span" animation="border" size="sm"/></div>)
    }

    // const ReactSelectMenu = props => {
    //     // console.log(props)
    //     return (<>
    //         <components.Menu {...props}><Form.Group><Form.Control type="text"
    //                                                               className="border-0 rounded-0"/></Form.Group>{props.children}
    //         </components.Menu>
    //     </>)
    // }

    // جهت ایجاد افکت باز شدن شبیه مودال
    const reactSelectMenuOpenHandler = () => {
        setTimeout(() => {
            if (document.querySelector('.react-select__menu'))
                document.querySelector('.react-select__menu').classList.add('run')
        }, 100)
    }

    // جهت ایجاد افکت بسته شدن شبیه مودال
    const reactSelectMenuCloseHandler = id => {

        // if (document.querySelector(`#${id} .react-select__menu`) && windowWidth < 767) {
        if (document.querySelector(`#${id} .react-select__menu`) && windowWidth < 768) {
            const $menu = document.querySelector(`#${id} .react-select__menu`);
            const $container = $menu.parentElement;
            const $menuCloned = $menu.cloneNode(true);

            if (!$menuCloned) return;

            $container.appendChild($menuCloned)

            setTimeout(() => {
                $menuCloned.classList.remove('run')
            }, 0)

            setTimeout(() => {
                $container.removeChild($menuCloned);
            }, 300)
        }

    }

    const asyncOptions = async inputValue => {

        // console.log(state)
        // console.log(props)

        if (props.stopFetchOnInputChange) {

            if (state.asyncSelect.id !== props.asyncSelectData[0]) {

                if (state.asyncSelect.id) {
                    dispatch({
                        type: 'CHANGE',
                        val: '',
                        validators: props.validators
                    })
                }

                try {

                    const responseData = await callAPI(
                        props.asyncSelectData[1],
                        props.asyncSelectData[3] === 'POST' ? 'POST' : 'GET',
                        props.asyncSelectData[3] === 'POST' ? props.asyncSelectData[0] : null
                    )

                    // console.log(responseData)

                    if (responseData[props.asyncSelectData[2]]) {

                        dispatch({
                            type: 'ASYNC_SELECT',
                            asyncSelect: {
                                id: props.asyncSelectData[0],
                                options: responseData[props.asyncSelectData[2]]
                            }
                        })

                        return responseData[props.asyncSelectData[2]]

                    }

                } catch (err) {
                    console.error(err)
                }

            } else return state.asyncSelect.options.filter(item => item.label.toLowerCase().includes(inputValue.toLowerCase()));

        } else {

            try {

                const responseData = await callAPI(
                    props.asyncSelectData[1]
                )

                if (responseData.data)
                    return responseData.data[props.asyncSelectData[2]]

            } catch (err) {
                console.error(err)
            }

        }

    }

    // -----------------------------------------------------------------------------------------------------------------

    if (props.component === 'INPUT') {
        const inputContent = <Form.Control type={props.type} name={props.name} value={state.value}
                                           className={props.className && props.className}
                                           isInvalid={!state.isValid && state.isBlurred}
                                           readOnly={props.readOnly} disabled={props.disabled} onChange={changeHandler}
                                           onBlur={blurHandler}
                                           maxLength={props.maxLength} onClick={props.onClick && props.onClick}
                                           onKeyUp={props.onKeyUp && props.onKeyUp}
                                           onKeyDown={props.onKeyDown && props.onKeyDown}
                                           aria-describedby={props['helpText'] && `${props.id}Help`}
                                           placeholder={props.placeholder && props.placeholder} inputMode={props.inputMode && props.inputMode}
                                           autoComplete={props.autocomplete && props.autocomplete}/>
        return (
            <Form.Group controlId={props.id} className="mb-3 position-relative">
                {props.label && <Form.Label>{props.label}</Form.Label>}
                {
                    props['prepend'] || props['append'] ? (
                        <InputGroup className={!state.isValid && state.isBlurred && 'is-invalid'}>
                            {props['prepend'] ? props['prepend']?.type?.displayName === 'Button' ? props['prepend'] : <InputGroup.Text>{props['prepend']}</InputGroup.Text> : ''}
                            {inputContent}
                            {props['append'] ? props['append']?.type?.displayName === 'Button' ? props['append'] : <InputGroup.Text>{props['append']}</InputGroup.Text> : ''}
                        </InputGroup>
                    ) : (
                        inputContent
                    )
                }
                {props['helpText'] && <Form.Text id={`help${props.id.charAt(0).toUpperCase() + props.id.slice(1)}`}
                                                 muted>{props['helpText']}</Form.Text>}
                {props.validators &&
                <Form.Control.Feedback type="invalid" tooltip>{props.textInvalid}</Form.Control.Feedback>}
            </Form.Group>
        )
    } else if (props.component === 'TEXTAREA')
        return (
            <Form.Group controlId={props.id} className="mb-3 position-relative">
                {props.label && <Form.Label>{props.label}</Form.Label>}
                <Form.Control as="textarea" name={props.name} value={state.value}
                              className={props.className && props.className}
                              isInvalid={!state.isValid && state.isBlurred}
                              readOnly={props.readOnly} disabled={props.disabled} onChange={changeHandler}
                              onBlur={blurHandler}
                              maxLength={props.maxLength} onClick={props.onClick && props.onClick}
                              onKeyUp={props.onKeyUp && props.onKeyUp} onKeyDown={props.onKeyDown && props.onKeyDown}
                              aria-describedby={props['helpText'] && `${props.id}Help`}
                              placeholder={props.placeholder && props.placeholder}
                              rows={props.rows && props.rows}/>
                {props['helpText'] && <Form.Text id={`help${props.id.charAt(0).toUpperCase() + props.id.slice(1)}`}
                                                 muted>{props['helpText']}</Form.Text>}
                {props.validators &&
                <Form.Control.Feedback type="invalid" tooltip>{props.textInvalid}</Form.Control.Feedback>}
            </Form.Group>
        )
    else if (props.component === 'INPUT_TOGGLE_PASSWORD')
        return (
            <Form.Group controlId={props.id} className="position-relative">
                <Form.Label>{props.label}</Form.Label>
                <InputGroup className="toggle-password">
                    <Form.Control type={togglePasswordType} name={props.name} value={state.value}
                                  className={props.className && props.className}
                                  isInvalid={!state.isValid && state.isBlurred}
                                  onChange={changeHandler} onBlur={blurHandler} maxLength={props.maxLength}/>
                    <InputGroup.Append className="position-absolute">
                        <Button variant="link" className="btn-icon text-black text-opacity-54"
                                onMouseDown={togglePasswordTypeHandler} onMouseUp={togglePasswordTypeHandler}
                                onMouseLeave={togglePasswordTypeHandler}><IconFi.FiEye size={24} className="sw-1"/></Button>
                    </InputGroup.Append>
                    {props.validators &&
                    <Form.Control.Feedback type="invalid" tooltip>{props.textInvalid}</Form.Control.Feedback>}
                </InputGroup>
            </Form.Group>
        )
    else if (props.component === 'CHECKBOX')
        return (
            <Form.Group className="position-relative">
                <Form.Check type="checkbox" id={props.id} name={props.name}
                            label={props.label} inline={props.inline}
                            defaultChecked={props.editValue}
                            className={`ps-0 form-check-custom${props.className ? ` ${props.className}` : ''}`}
                            isInvalid={!state.isValid && state.isBlurred}
                            onChange={changeHandler} onBlur={blurHandler}/>
                {props.validators &&
                <Form.Control.Feedback type="invalid" tooltip>{props.textInvalid}</Form.Control.Feedback>}
            </Form.Group>
        )
    else if (props.component === 'RADIO_GROUP')
        return (
            <Form.Group className="mb-3 position-relative d-flex flex-wrap align-items-center radio-group">
                {
                    props.labelGroup && <Form.Label
                        className={`${props['inlineLabel'] ? 'mb-0 me-4' : 'col-12 px-0'}`}>{props.labelGroup}</Form.Label>
                }
                {
                    props.radioGroup.map((radio, index) =>
                        <Form.Check key={`radio${props.id.charAt(0).toUpperCase() + props.id.slice(1)}${index}`}
                                    type="radio"
                                    id={`radio${props.id.charAt(0).toUpperCase() + props.id.slice(1)}${index}`}
                                    name={`radio${props.name.charAt(0).toUpperCase() + props.name.slice(1)}`}
                                    value={radio.value}
                                    disabled={props.disabled}
                                    label={radio.label} inline={props.inline}
                                    defaultChecked={props.editValue === radio.value}
                                    className={`form-check-custom ${props.inline ? 'ps-0' : 'col-12 px-0'}${!state.isValid && state.isBlurred ? ' is-invalid' : ''}`}
                                    onChange={changeHandler} onBlur={blurHandler}
                                    onClick={props.onClick && props.onClick}/>
                    )
                }
                <Form.Control type="text" id={props.id} name={props.name} value={state.value}
                              className="position-absolute p-0 border-0 w-0 h-0"
                              isInvalid={!state.isValid && state.isBlurred} onChange={changeHandler}
                              onBlur={blurHandler}/>
                {props.validators &&
                <Form.Control.Feedback type="invalid" tooltip>{props.textInvalid}</Form.Control.Feedback>}
            </Form.Group>
        )
    else if (props.component === 'SWITCH')
        return (
            <Form.Group className="mb-3 position-relative">
                <Form.Check type="switch" id={props.id} name={props.name} label={props.label}
                            defaultChecked={props.editValue}
                            className={`ps-0 form-check-custom${props.className ? ` ${props.className}` : ''}`}
                            isInvalid={!state.isValid && state.isBlurred}
                            onClick={props.onClick} onChange={changeHandler}/>
                {props.validators &&
                <Form.Control.Feedback type="invalid" tooltip>{props.textInvalid}</Form.Control.Feedback>}
            </Form.Group>
        )
    else if (props.component === 'FILE')
        return (
            <>
                <Form.Group controlId={props.id} className="mb-3 position-relative file-input-custom">
                    {
                        props.label && (
                            <Form.Label>{props.label}</Form.Label>
                        )
                    }
                    {/*<Form.Control type="file" name={props.name} value={props.value ? props.value : state.value}*/}
                    <Form.Control type="file" name={props.name} value={props.value ? props.value : state.value.fileList ? state.value.fileList[0].val : ''}
                                  accept={props.accept} isInvalid={!state.isValid && state.isBlurred}
                                  multiple={props.multiple && props.multiple}
                                  onChange={changeHandler} onBlur={blurHandler}/>
                    {props.validators &&
                    <Form.Control.Feedback type="invalid" tooltip>{props.textInvalid}</Form.Control.Feedback>}
                </Form.Group>

                {/*<Form.File id={props.id}>*/}
                {/*    <Form.File.Input name={props.name} value={props.value ? props.value : state.value} accept={props.accept} onChange={changeHandler} onBlur={blurHandler}/>*/}
                {/*    <Form.File.Label data-browse={props['browse']} className={`d-flex justify-content-between${props.className ? ` ${props.className}` : ''}`} isInvalid={!state.isValid && state.isBlurred}>*/}
                {/*        <span className="text-truncate flex-shrink-1">{props.label}</span>*/}
                {/*    </Form.File.Label>*/}
                {/*</Form.File>*/}
                {/*{props.validators && <Form.Control.Feedback type="invalid" tooltip>{props.textInvalid}</Form.Control.Feedback>}*/}
            </>
        )
    else if (props.component === 'SELECT') {

        let selectValue = ''

        if (props.stopFetchOnInputChange) {
            // console.log(props)
            // console.log(state)
            // selectValue = !state.asyncSelect ? props.editValue : state.value
            selectValue = state.asyncSelect ? state.asyncSelect.options && state.asyncSelect.options.find(option => option.value === state.value) ? state.asyncSelect.options.find(option => option.value === state.value) : '' : props.editValue
        } else {
            // selectValue = state.value === '' && props.editValue ? props.editValue : state.value
            // selectValue = state.value === '' && props.editValue ? props.editValue : props.options ? props.options.find(option => option.value === state.value) : ''
            // selectValue = (state.value === '' && props.editValue) ? props.editValue : props.options ? props.options.find(option => option.value === state.value) ? props.options.find(option => option.value === state.value) : null : ''
            // selectValue = ((state.value === '' || state.value?.value) && props.editValue) ? props.editValue : props.options ? props.options.find(option => option.value === state.value) ? props.options.find(option => option.value === state.value) : null : ''
            if ((state.value === '' || state.value?.value || state.value?.value === '') && (props.editValue || props.editValue === '')) selectValue = props.options.find(option => option.value === props.editValue)
            else if (props.options) {
                if (props.options.find(option => option.value === state.value)) selectValue = props.options.find(option => option.value === state.value)
                else selectValue = null
            } else selectValue = ''
        }

        // برای ریست کردن select
        if (props.clear) selectValue = null
        
        const selectProps = {
            // defaultMenuIsOpen: true,
            // isLoading: true,
            // isInvalid: !state.isValid && state.isBlurred,
            // defaultValue: props.defaultValue,
            menuPlacement: props.menuPlacement ? props.menuPlacement : 'auto',
            id: props.id,
            name: props.name,
            // label: props.label,
            isClearable: !!props.clearable,
            isMulti: !!props.multiple,
            value: selectValue,
            getOptionLabel: props.getOptionLabel && props.getOptionLabel,
            getOptionValue: props.getOptionValue && props.getOptionValue,
            isDisabled: !!props.disabled,
            className: `react-select${props.className ? ` ${props.className}` : ''}${!state.isValid && state.isBlurred ? ' is-invalid' : ''}`,
            placeholder: props.placeholder ? props.placeholder : 'انتخاب کنید...',
            isRtl: true,
            isSearchable: !!props.searchable,
            components: {
                DropdownIndicator: ReactSelectDropdownIndicator,
                ClearIndicator: ReactSelectClearIndicator,
                IndicatorSeparator: () => null,
                LoadingIndicator: ReactSelectLoadingIndicator,
                // Menu: ReactSelectMenu
            },
            noOptionsMessage: () => 'انتخابی یافت نشد',
            classNamePrefix: 'react-select',
            onMenuOpen: reactSelectMenuOpenHandler,
            onMenuClose: () => reactSelectMenuCloseHandler(props.id),
            onChange: changeHandler,
            onBlur: blurHandler
        }

        return (
            <Form.Group className="position-relative">
                {
                    props.label && <Form.Label>{props.label}</Form.Label>
                }
                {
                    props.asyncSelectData ? (
                        // مقدار props.asyncSelectData[0] جهت رندر کردن با تغییر مسیر دریافت اطلاعات مورد نیاز است.
                        <AsyncSelect {...selectProps} key={`${props.id}${props.asyncSelectData[0]}`} cacheOptions
                                     defaultOptions={!!props.asyncSelectData}
                                     loadOptions={asyncOptions} loadingMessage={() => 'در حال دریافت اطلاعات...'}/>
                    ) : (
                        <Select {...selectProps} options={props.options}/>
                    )
                }
                {
                    props.validators && <Form.Control.Feedback type="invalid" tooltip>{props.textInvalid}</Form.Control.Feedback>
                }
            </Form.Group>
        )
    }
}

export default FormElement