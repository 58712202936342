import React from 'react';

import {Container} from 'react-bootstrap';

import FooterRow1 from './FooterRow1'
import FooterRow2 from './FooterRow2'
import FooterRow3 from './FooterRow3'

import './Footer.scss'

const Footer = props => {

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <footer id="footer">
            <Container fluid="lg" className="my-4 my-lg-5 pt-2 pt-lg-4 pb-lg-3">
                <FooterRow1/>
                <FooterRow2 data={props?.data?.contactUs}/>
            </Container>
            <FooterRow3 data={props?.data?.socialMedia}/>
        </footer>
    )
}

export default Footer;