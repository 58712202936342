import React from 'react';
import {useNavigate, Link, useLocation} from 'react-router-dom';
import {useSwipeable} from 'react-swipeable';

import Tags from '../../../common/component/Tags/Tags';

import {Offcanvas, Button, Row, Col, Container} from 'react-bootstrap';

import * as IconFi from 'react-icons/fi';
// import * as IconTb from 'react-icons/tb';

import './Map.scss';

const OffcanvasMap = props => {
    const navigate = useNavigate()
    const location = useLocation()

    const data = props.data;

    // --------------------------------------------------------------------------------------------------- show and hide

    const handlePopstate = res => {

        if (document.querySelectorAll('.offcanvas').length < 2)
            window.removeEventListener('popstate', handlePopstate)

        props.onHide(res === 'hideModal' ? res : null)

    }

    const onShowHandler = () => {

        document.querySelector('#offcanvasMap > div').style.paddingRight = document.querySelector('body').style.paddingRight

        navigate(`${location.pathname}${location.hash}#offcanvasMap`)
        window.addEventListener('popstate',  handlePopstate);

    }

    const onHideHandler = () => {

        navigate(-1)

    }

    const swipe = useSwipeable({
        onSwiping: (data) => {

            if (data.deltaY > 0 && data.deltaY < data.event.target.closest('.offcanvas').offsetHeight)
                data.event.target.closest('.offcanvas').style.transform = `translateY(${data.deltaY}px)`

        },
        onSwiped: (data) => {

            if (data.velocity > .9) {

                data.event.target.closest('.offcanvas').style.transform = `translateY(100%)`
                onHideHandler()

            } else {

                let h = data.event.target.closest('.offcanvas').offsetHeight
                let translateY = getComputedStyle(data.event.target.closest('.offcanvas')).transform.match(/matrix.*\((.+)\)/)[1].split(', ')[5]

                if (translateY > h / 2) {

                    data.event.target.closest('.offcanvas').style.transform = `translateY(100%)`
                    onHideHandler()

                } else data.event.target.closest('.offcanvas').style.transform = ''
            }

        }
    });

    // -----------------------------------------------------------------------------------------------------------------

    // از کلاس fixed-bottom استفاده شد تا padding-right ایجاد گردد
    return (
        <Offcanvas id="offcanvasMap" {...props} placement="bottom" backdrop={false} onHide={onHideHandler} onShow={onShowHandler} className="ltr fixed-bottom shadow" tabIndex="">
            <div className="w-100vw">
                <Container fluid="lg" className="px-0 rtl">
                    <Offcanvas.Header className="position-sticky top-0 bg-white flex-column flex-md-row">
                        <Button variant="link" className="pt-1 pb-3 align-self-stretch d-flex justify-content-center d-md-none minimize" onClick={onHideHandler} onTouchStart={e => e.target.closest('.offcanvas').classList.add('swiping')} onTouchEnd={e => e.target.closest('.offcanvas').classList.remove('swiping')} {...swipe}/>
                        <Offcanvas.Title>{data?.label}</Offcanvas.Title>
                        <Button variant="link" className="text-reset border-0 btn-icon d-none d-md-inline-flex" aria-label="Close" onClick={onHideHandler}><IconFi.FiX size={24} className="m-2 sw-1"/></Button>
                    </Offcanvas.Header>
                    <Offcanvas.Body className={`${data ? 'pb-0 ' : ''}overflow-visible`}>
                        {
                            data ? (
                                <>
                                    <Row className="gx-3 gy-2">
                                        <Col xs={12} className="fs-5">طبقه <span className="mx-1">{data.floor}</span> غرفه <span className="ms-1">{data.value}</span></Col>
                                        {
                                            data.services && (
                                                <Col xs={12} dangerouslySetInnerHTML={{__html: data.services}}/>
                                            )
                                        }
                                        {
                                            (data.phones?.length || data.networkAddresses?.length) ? (
                                                <Col xs={12} className="pt-md-2">ارتباط</Col>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        {
                                            data.phones?.length ? (
                                                <Col md="auto" className="d-grid pe-md-5 contact">
                                                    <Button variant="outline-secondary" href={`tel:+98${data.phones[0].startsWith('0') ? data.phones[0].replaceAll(' ', '').substring(1) : data.phones[0].replaceAll(' ', '')}`} className="px-md-0 fs-18px">
                                                        <IconFi.FiPhone size={24} className="sw-1 me-2 me-xl-3 flip-h"/>
                                                        <span className="ltr">+98 {data.phones[0].startsWith('0') ? data.phones[0].substring(1).match(/.{1,2}/g)?.join(' ') : data.phones[0].match(/.{1,2}/g)?.join(' ')}</span>
                                                    </Button>
                                                </Col>
                                            ) : (<></>)
                                        }
                                        {
                                            data.networkAddresses?.length ? (
                                                <Col md="auto" className="d-grid contact">
                                                    <Button variant="outline-secondary" href={`mailto:${data.networkAddresses.find(item => item.includes('@'))}`} className="px-md-0 fs-8">
                                                        <IconFi.FiMail size={24} className="sw-1 me-2 me-xl-3 flip-h"/>
                                                        {data.networkAddresses.find(item => item.includes('@'))}
                                                    </Button>
                                                </Col>
                                            ) : (<></>)
                                        }
                                        {
                                            data.tags?.length ? (
                                                <>
                                                    <Col xs={12} className="pt-2">برچسب های مربوط</Col>
                                                    <Col xs={12}>
                                                        <Row className="g-3">
                                                            <Tags tags={data.tags} path={''}/>
                                                        </Row>
                                                    </Col>
                                                </>
                                            ) : (<></>)
                                        }
                                    </Row>
                                    {/*<Row className="g-3 pt-4 pt-lg-5 position-sticky bottom-0 bg-light">*/}
                                    {/*<Row className="mt-4 mt-lg-5 d-grid position-sticky bottom-0 bg-light">*/}
                                    {/*<Col sm className="d-grid pb-2 pb-sm-0 mb-1 mb-sm-0"><Button variant="outline-primary" className="offcanvas-btn px-0"><IconTb.TbCurrentLocation size={24} className="sw-1 me-2"/>انتخاب به عنوان مبدا</Button></Col>*/}
                                    {/*<Col sm className="d-grid pb-2 pb-sm-0 mb-1 mb-sm-0"><Button variant="outline-primary" className="offcanvas-btn px-0"><IconFi.FiMapPin size={24} className="sw-1 me-2"/>انتخاب به عنوان مقصد</Button></Col>*/}
                                    {/*<Col sm className="d-grid"><Button as={Link} to={`/${data.type === '1' ? 'shop' : data.type === '2' ? 'dine' : 'entertain'}/${data.id}`} variant="primary" className="offcanvas-btn">مشاهده اطلاعات بیشتر</Button></Col>*/}
                                    {/*</Row>*/}
                                    <div className="position-sticky bottom-0 py-2 pb-md-4 pb-xl-5 d-grid offcanvas-btn">
                                        <Button as={Link} to={`/${data.type === '1' ? 'shop' : data.type === '2' ? 'dine' : 'entertain'}/${data.id}`} className="my-1" onClick={() => handlePopstate('hideModal')}>مشاهده صفحه {data.title}</Button>
                                    </div>
                                </>
                            ) : (
                                <div className="d-flex flex-column justify-content-center align-items-center text-danger"><IconFi.FiAlertTriangle size={48} className="sw-1 mb-3"/><span className="fs-5">اطلاعات غرفه دریافت نشد</span></div>
                            )
                        }
                    </Offcanvas.Body>
                </Container>
            </div>
        </Offcanvas>
    )
}

export default OffcanvasMap;