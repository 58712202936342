import React from 'react';

import {Container, Row, Col, Button} from 'react-bootstrap';

import {ReactComponent as ImgAccessDenied} from '../../../asset/img/icon/access-denied.svg';

const AccessDenied = () => {

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <div id="pageAccessDenied">
            <Container fluid="lg">
                <Row className="py-5">
                    <Col xs={12} className="d-flex flex-column justify-content-center align-items-center text-center text-secondary my-5">
                        <ImgAccessDenied width={48} height={48} className="sw-1 mb-4"/>
                        <p className="fs-5 mb-4">کاربر گرامی، لطفا به حساب کاربری خود وارد شوید</p>
                        <Button variant="secondary" className="px-5 text-accent-3" onClick={() => document.querySelector('#accountBtn').click()}>ورود به حساب کاربری</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AccessDenied;