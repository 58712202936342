import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import Form from './Form'

import {Modal, Button} from 'react-bootstrap';

import {ReactComponent as ImgLogo} from '../../../asset/img/micamall-logo-sign.svg';
import * as IconFi from 'react-icons/fi';

import './Login.scss'
import GlobalConst from "../../../common/global-const";

const ModalLogin = props => {
    const navigate = useNavigate()

    // --------------------------------------------------------------------------------------------------- show and hide

    const handlePopstate = useCallback(() => {

        // اگر popstate بود مودال بسته نشود و back هم غیرفعال می شود، این مودال فقط با دکمه close بسته می شود
        navigate(1)

    }, [])

    const onShowHandler = () => {

        document.getElementById('modalLogin').style.paddingRight = document.querySelector('body').style.paddingRight
        document.querySelector('#modalLogin .modal-content > figure > span:nth-child(2)').style.backgroundPosition = `calc(50%${document.querySelector('body').style.paddingRight ? ` - (${document.querySelector('body').style.paddingRight} / 2)` : ''}) top`

        let timeout = setTimeout(() => {

            navigate('#modalLogin')

            clearTimeout(timeout)

        }, 0)

        window.addEventListener('popstate',  handlePopstate);

    }

    const onHideHandler = item => {

        window.redirect = ''

        if (item !== 'redirect') navigate(-1)

        if (document.querySelectorAll('.modal').length < 2)
            window.removeEventListener('popstate', handlePopstate)

        props.onHide()

    }

    // -----------------------------------------------------------------------------------------------------------------

    return (
        // مودال می بایست با کیبورد و back مرورگر بسته نشود، overflow-hidden اسکرول بار نمایش داده شده با دکمه scape را مخفی می کند
        <Modal id="modalLogin" aria-labelledby="modalLoginTitle" {...props} backdrop="static" fullscreen onShow={onShowHandler} keyboard={false} className="ltr bg-white overflow-hidden" dialogClassName="rtl d-flex h-auto min-h-100" contentClassName="bg-transparent h-auto">
            <figure className="mb-0 position-absolute top-0 bottom-0 end-0 z-index-n1 filter-warm w-100vw">
                <span className="filter-level"/>
                <span className="position-absolute top-0 start-0 bottom-0 end-0" style={{backgroundImage: `url(${GlobalConst.cdn}public-portal/img/login-bg.jpg)`}}/>
                <span className="filter-gradient"/>
            </figure>
            <Modal.Body className="p-0 d-flex flex-column mx-auto">
                <div className="ratio ratio-1x1 bg-accent">
                    <div className="d-flex flex-column justify-content-end align-items-start px-4 pb-4 pt-2">
                        <Button variant="link" className="border-0 btn-icon mb-auto text-light d-lg-none" aria-label="Close" onClick={onHideHandler}><IconFi.FiArrowRight size={24} className="m-2 sw-1"/></Button>
                        <Button variant="link" className="border-0 btn-icon mb-auto text-light me-n3 d-none d-lg-block align-self-end" aria-label="Close" onClick={onHideHandler}><IconFi.FiX size={24} className="m-2 sw-1"/></Button>
                        <ImgLogo width="60.74" height="47.73" className="mb-2"/>
                        <Modal.Title id="modalLoginTitle" className="fw-bold mb-0 fs-2 text-white">ورود کاربران</Modal.Title>
                    </div>
                </div>
                <div className="ratio ratio-1x1 bg-white">
                    <div className="p-4 overflow-hidden">
                        <div className="h-100 position-relative">
                            <Form onHide={onHideHandler}/>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalLogin;