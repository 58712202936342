const showValidators = (form) => {

    for (let element in form) {
        if (!form[element].isValid) {
            if (document.getElementById(element).classList.contains('react-select')) {
                document.querySelector(`#${element} input`).focus();
                document.querySelector(`#${element} input`).blur();
            } else {
                document.getElementById(element).focus();
                document.getElementById(element).blur();
            }
        }
    }

}

export default showValidators