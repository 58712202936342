import React from 'react';

// نام و مسیر component از مقدار alias به دست می آید
export const routesArray = [
    {id: 1, alias: 'home', path: '/', access: 'public', icon: '', title: 'صفحه نخست', menu: true},
    {id: 2, alias: 'shop', path: 'shop', access: 'public', icon: '', title: 'خرید کنید', menu: true},
    {id: 3, alias: 'shop/directory', path: 'shop/directory', access: 'public', icon: '', title: 'فهرست خرید کنید', menu: false},
    {id: 4, alias: 'shop/item', path: 'shop/:id', access: 'public', icon: '', title: '', menu: false},
    {id: 5, alias: 'dine', path: 'dine', access: 'public', icon: '', title: 'میل کنید', menu: true},
    {id: 6, alias: 'dine/directory', path: 'dine/directory', access: 'public', icon: '', title: 'فهرست میل کنید', menu: false},
    {id: 7, alias: 'dine/item', path: 'dine/:id', access: 'public', icon: '', title: '', menu: false},
    {id: 8, alias: 'entertain', path: 'entertain', access: 'public', icon: '', title: 'تفریح کنید', menu: true},
    {id: 9, alias: 'entertain/item', path: 'entertain/:id', access: 'public', icon: '', title: '', menu: false},
    {id: 10, alias: 'entertain/ticket-order', path: 'entertain/ticket-order', access: 'private', icon: '', title: 'خرید آنلاین بلیط', menu: false},
    {id: 11, alias: 'entertain/ticket-order', path: 'entertain/ticket-order/:id', access: 'private', icon: '', title: '', menu: false},
    {id: 12, alias: 'plan-your-visit', path: 'plan-your-visit', access: 'public', icon: '', title: 'بازدید خود را برنامه ریزی کنید', menu: true,
        children: [
            {id: 1201, alias: 'plan-your-visit/tabs', path: 'working-hours', access: 'public', icon: '', title: 'ساعات کار', menu: true},
            {id: 1202, alias: 'plan-your-visit/tabs', path: 'getting-here', access: 'public', icon: '', title: 'مسیریابی', menu: true},
            {id: 1203, alias: 'plan-your-visit/tabs', path: 'parking', access: 'public', icon: '', title: 'پارکینگ', menu: true}
        ]
    },
    {id: 13, alias: 'plan-your-visit', path: 'plan-your-visit/services', access: 'public', icon: '', title: 'خدمات', menu: false},
    {id: 14, alias: 'about-us', path: 'about-us', access: 'public', icon: '', title: 'درباره ما', menu: false},
    {id: 15, alias: 'about-us', path: 'about-us/:path', access: 'public', icon: '', title: '', menu: false},
    {id: 16, alias: 'news', path: 'news', access: 'public', icon: '', title: 'خبرها', menu: true},
    {id: 17, alias: 'news/item', path: 'news/:id', access: 'public', icon: '', title: '', menu: false},
    {id: 18, alias: 'whats-new', path: 'whats-new', access: 'public', icon: '', title: 'تازه ها', menu: true},
    {id: 19, alias: 'whats-new/item', path: 'whats-new/:id', access: 'public', icon: '', title: '', menu: false},
    {id: 20, alias: 'customer-club', path: 'customer-club', access: 'public', icon: '', title: 'باشگاه مشتریان', menu: true},
    {id: 21, alias: 'offers', path: 'offers', access: 'public', icon: '', title: 'تخفیف ها', menu: true},
    {id: 22, alias: 'offers/item', path: 'offers/:id', access: 'public', icon: '', title: '', menu: false},
    {id: 23, alias: 'events', path: 'events', access: 'public', icon: '', title: 'رویدادها و جایزه ها', menu: true},
    {id: 24, alias: 'events/item', path: 'events/:id', access: 'public', icon: '', title: '', menu: false},
    // {id: 25, alias: 'map', path: 'map', access: 'public', icon: '', title: 'نقشه میکا مال', menu: false},
    {id: 26, alias: 'services', path: 'services', access: 'public', icon: '', title: 'خدمات', menu: false},
    {id: 27, alias: 'services/gift-card', path: 'services/gift-card', access: 'public', icon: '', title: 'کارت هدیه', menu: false},
    {id: 28, alias: 'services/expert-companion', path: 'services/expert-companion', access: 'public', icon: '', title: 'همراه متخصص', menu: false},
    {id: 29, alias: 'payment-result', path: 'payment-result', access: 'private', icon: '', title: 'نتیجه پرداخت', menu: false},
    {id: 100, alias: 'ticket', path: 'ticket/:id', access: 'public', icon: '', title: 'بلیط', menu: false},
    {id: 200, alias: 'e-services', path: 'e-services', access: 'public', icon: '', title: 'خدمات الکترونیک', menu: false},
    {id: 201, alias: 'e-services/real-estate', path: 'e-services/real-estate', access: 'public', icon: '', title: 'امور املاک', menu: false,
        children: [
            {id: 20101, alias: 'e-services/real-estate/tabs', path: 'buy', access: 'public', icon: '', title: 'خرید فروشگاه', menu: false},
            {id: 20102, alias: 'e-services/real-estate/tabs', path: 'leasing', access: 'public', icon: '', title: 'اجاره فروشگاه', menu: false},
        ]
    },
    // {id: 202, alias: 'e-services/real-estate/request', path: 'e-services/real-estate/buy/:id', access: 'private', icon: '', title: '', menu: false},
    {id: 202, alias: 'e-services/real-estate/request', path: 'e-services/real-estate/buy/request', access: 'private', icon: '', title: '', menu: false},
    // {id: 203, alias: 'e-services/real-estate/request', path: 'e-services/real-estate/leasing/:id', access: 'private', icon: '', title: '', menu: false},
    {id: 203, alias: 'e-services/real-estate/request', path: 'e-services/real-estate/leasing/request', access: 'private', icon: '', title: '', menu: false},
    {id: 204, alias: 'e-services/real-estate/result', path: 'e-services/real-estate/buy/result', access: 'private', icon: '', title: 'نتیجه درخواست', menu: false},
    {id: 205, alias: 'e-services/real-estate/result', path: 'e-services/real-estate/leasing/result', access: 'private', icon: '', title: 'نتیجه درخواست', menu: false},
    {id: 210, alias: 'e-services/careers', path: 'e-services/careers', access: 'public', icon: '', title: 'فرصت های شغلی', menu: false},
    {id: 210, alias: 'e-services/careers/category', path: 'e-services/careers/category/:id', access: 'public', icon: '', title: '', menu: false},
    {id: 211, alias: 'e-services/careers/request', path: 'e-services/careers/request/:id', access: 'private', icon: '', title: '', menu: false},
    {id: 212, alias: 'e-services/careers/result', path: 'e-services/careers/request/result', access: 'private', icon: '', title: 'نتیجه درخواست', menu: false},
    {id: 10000, alias: 'account', path: 'account', access: 'private', icon: '', title: 'حساب کاربری', menu: false,
        children: [
            {id: 10001, alias: 'account/notification', path: 'notification', access: 'private', icon: '', title: 'پیام ها', menu: false, empty: true},
            {id: 10002, alias: 'account/customer-club', path: 'customer-club', access: 'private', icon: '', title: 'باشگاه مشتریان', menu: false},
            {id: 10003, alias: 'account/customer-club-card-number', path: 'customer-club-card-number', access: 'private', icon: '', title: 'شماره کارت بانک', menu: false},
            {id: 10004, alias: 'account/mica-coin', path: 'mica-coin', access: 'private', icon: '', title: 'Mica Coin', menu: false},
            {id: 10005, alias: 'account/mica-coin-lottery', path: 'mica-coin-lottery', access: 'private', icon: '', title: 'قرعه کشی', menu: false},
            {id: 10006, alias: 'account/mica-coin-discount-code', path: 'mica-coin-discount-code', access: 'private', icon: '', title: 'تخفیف', menu: false},
            {id: 10007, alias: 'account/mica-plus', path: 'mica-plus', access: 'private', icon: '', title: 'Mica Credit', menu: false},
            {id: 10008, alias: 'account/mica-ticket', path: 'mica-ticket', access: 'private', icon: '', title: 'Mica Cash', menu: false},
            {id: 10009, alias: 'account/expert-companion', path: 'expert-companion', access: 'private', icon: '', title: 'همراه متخصص', menu: false, empty: true},
            {id: 10010, alias: 'account/tickets', path: 'tickets', access: 'private', icon: '', title: 'بلیط ها', menu: false},
            {id: 10011, alias: 'account/payments', path: 'payments', access: 'private', icon: '', title: 'پرداخت ها', menu: false, empty: true},
            {id: 10012, alias: 'account/profile', path: 'profile', access: 'private', icon: '', title: 'پروفایل', menu: false},
            {id: 10013, alias: 'account/settings', path: 'settings', access: 'private', icon: '', title: 'تنظیمات', menu: false, empty: true},
            {id: 10014, alias: 'account/real-estate', path: 'real-estate', access: 'private', icon: '', title: 'امور املاک', menu: false},
            {id: 10015, alias: 'account/careers', path: 'careers', access: 'private', icon: '', title: 'فرصت های شغلی', menu: false}
        ]
    }
];

const getRoutes = async routes => {
    const routesTemp = [];

    const setRoute = async item => {

        const component = {data: null, name: null}

        const handleError = error => {

            console.log(error)
            //             localStorage.removeItem('auth')
            //             window.location.reload(false)

        }

        if(item.empty) {

            try {
                component.data = await import(`../public/page/Empty/Empty`)
                item['component'] = React.createElement(component.data.default);
            } catch (e) {
                handleError(e)
            }

        } else if (item.alias.includes('/')) {

            component.name = item.alias.split('/')
            component.name[0] = component.name[0].split('-').map((word) => word.charAt(0).toUpperCase() + word.substring(1)).join('')
            component.name[1] = component.name[1].split('-').map((word) => word.charAt(0).toUpperCase() + word.substring(1)).join('')
            if (component.name[2])
                component.name[2] = component.name[2].split('-').map((word) => word.charAt(0).toUpperCase() + word.substring(1)).join('')

            try {

                if (component.name[2])
                    component.data = await import(`../${item.access}/page/${component.name[0]}/${component.name[1]}/${component.name[2]}/${component.name[2]}`)
                else
                    component.data = await import(`../${item.access}/page/${component.name[0]}/${component.name[1]}/${component.name[1]}`)

                item['component'] = React.createElement(component.data.default);
            } catch (e) {
                handleError(e)
            }

        } else {

            component.name = item.alias.split('-').map((word) => word.charAt(0).toUpperCase() + word.substring(1)).join('')

            try {
                component.data = await import(`../${item.access}/page/${component.name}/${component.name}`)
                item['component'] = React.createElement(component.data.default);
            } catch (e) {
                handleError(e)
            }

        }

        return item;
    }

    for (const item of routes) {

        if (item.path) {

            const route = await setRoute(item)
            routesTemp.push(route)

        }

        if (item.children) {

            for (const child of item.children) {

                if (child.path) {

                    const route = await setRoute(child)
                    routesTemp.push(route)

                }

            }

        }

    }

    return routesTemp
}

export default getRoutes;