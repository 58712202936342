import {useCallback, useRef, useEffect, useContext} from 'react'

import {ContextAuth} from '../context/context-auth';

import GlobalConst from '../global-const';

export const useHttpClient = () => {
    const auth = useContext(ContextAuth);

    const activeHttpRequests = useRef([])

    const callAPI = useCallback(async (
        url,
        method = 'GET',
        body = null,
        headers = {'Content-Type': 'application/json'},
        token
    ) => {

        if (navigator.onLine) {

            const httpAbortCtrl = new AbortController()
            activeHttpRequests.current.push(httpAbortCtrl)

            const tokenTemp = auth.token ? `Bearer ${auth.token}` : token ? `Bearer ${token}` : 'Bearer null'

            headers.Authorization = tokenTemp;

            if (headers['Content-Type'] === 'multipart/form-data') {

                let headersTemp = new Headers();
                headersTemp.append('Authorization', tokenTemp);
                headers = headersTemp

            }

            try {

                if (url.endsWith('/')) url = url.substring(1).slice(0, -1);

                // اگر با http یا https شروع شود، لینک خارجی یا دسترسی به محتوای فایل است

                const response = await fetch(url.startsWith('http') ? url : `${GlobalConst.api}${url}`, {
                    method: method,
                    body: body,
                    headers: headers,
                    signal: httpAbortCtrl.signal
                })

                const urlSplit = url.split('/')

                // اگر . داشته باشد دسترسی به محتوای فایل است

                const responseData = urlSplit[urlSplit.length - 1].includes('.') ? await response.text() : url.startsWith('http') ? await response.blob() : await response.json()

                activeHttpRequests.current = activeHttpRequests.current.filter(item => item !== httpAbortCtrl)

                if (!response.ok) throw new Error(responseData.message)

                return responseData

            } catch (err) {
                throw err
            }

        } else {
            return 'offline'
        }

    }, [])

    useEffect(() => {

        return () => {

            activeHttpRequests.current.forEach(item => item.abort())

        }

    }, [])

    return {callAPI}
}