const currency = (value, locale = 'fa') => {

    if (value) {

        if (locale === 'fa') {

            value = value.toString().replaceAll('/', '')
            // return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '/');
            return value.replace(/\B(?=(\d{3})+(?!\d))/g, '/');

        }

    } else return value

}

export default currency