import React, {useCallback, useEffect} from 'react';
import {useNavigate, Link} from 'react-router-dom';

import GlobalConst from '../../../common/global-const';

import {useWindowSize} from '../../../common/hook/window-size';

import {Container, Nav, NavDropdown, Row, Col} from 'react-bootstrap';

import * as IconFi from 'react-icons/fi';

const HeaderRow2 = props => {
    let navigate = useNavigate();
    const windowSize = useWindowSize()

    const data = props.data

    // -----------------------------------------------------------------------------------------------------------------

    // const entertainArray = [
    //     {id: 1, title: 'تونل باد', to: '/entertain', photo: 'tunnel'},
    //     {id: 2, title: 'شهربازی', to: '/entertain', photo: 'park'},
    //     {id: 3, title: 'مجتمع سینمایی', to: '/entertain', photo: 'cinema'},
    //     {id: 4, title: 'تله کابین', to: '/entertain/4', photo: 'telecabin'},
    //     {id: 5, title: 'جنگل بارانی', to: '/entertain', photo: 'jungle'},
    //     {id: 6, title: 'آکواریوم', to: '/entertain', photo: 'aqua'},
    //     {id: 7, title: 'دنیای اکتشاف', to: '/entertain', photo: 'disc'},
    //     {id: 8, title: 'گلف دیجیتال', to: '/entertain', photo: 'golf'},
    //     {id: 9, title: 'باشگاه بدنسازی', to: '/entertain', photo: 'gym'},
    //     {id: 10, title: 'پاتیناژ', to: '/entertain', photo: 'patinazh'},
    //     {id: 11, title: 'بولینگ', to: '/entertain', photo: 'bowling'},
    //     {id: 12, title: 'اسپا', to: '/entertain', photo: 'spa'}
    // ]

    // -----------------------------------------------------------------------------------------------------------------

    const handleMenuHide = useCallback(item => {

        item.classList.remove('show')
        item.querySelector('a').setAttribute('aria-expanded', 'false')
        item.querySelector('div').classList.remove('show')

    }, [])

    const navDropdownShowHandler = useCallback(e => {

        if (e.type === 'click') {

            if (windowSize[0] < 768) {

                if (e.target.closest('.dropdown').classList.contains('hide-children')) {

                    navigate(e.currentTarget.querySelector('a').getAttribute('href'))
                    document.querySelector('#header .navbar-toggler').click()

                } else if (e.target.classList.contains('dropdown-toggle')) {
                    // باز شدن یک زیر منو باعث بسته شدن دیگری نشود
                    e.stopPropagation()
                }

            } else if (e.target.classList.contains('dropdown-toggle')) navigate(e.currentTarget.querySelector('a').getAttribute('href'))

        } else if (e.type === 'mouseenter') {

            e.currentTarget.classList.add('show')
            e.currentTarget.querySelector('a').setAttribute('aria-expanded', 'true')
            e.currentTarget.querySelector('div').classList.add('show')

        } else if (e.type === 'mouseleave') {

            handleMenuHide(e.currentTarget)

        } else {

            handleMenuHide(document.querySelector('#header .dropdown.show'))

        }

    }, [windowSize])

    // -----------------------------------------------------------------------------------------------------------------

    const renderMenu = item => {

        let has3Level = !!item.children?.find(item2 => item2.children?.length)

        let childrenLength = item.children ? item.children.length > 4 ? 4 : item.children.length : 0

        let hasPhoto = false
        if (childrenLength && item.children[childrenLength - 1].children[0]?.photos) hasPhoto = !!item.children[childrenLength - 1].children[0].photos.length

        // اگر زیرمنو داشت، href به جای # مقدار link را خواهد داشت
        if (item.children) {

            let timeout = setTimeout(() => {

                document.querySelector(`#navbarDropdown${item.id}`).href = item.link ? item.link : '#'
                clearTimeout(timeout)

            }, 10)

        }

        return item.children ? (
            <NavDropdown id={`navbarDropdown${item.id}`} title={<>{item.title}<IconFi.FiChevronDown size={18} className="sw-1 ms-auto ms-md-1 ms-lg-2"/></>}
                         className={`position-static${has3Level || item.children.find(({title}) => title === 'entertainment') ? ' hide-children' : ''}`} renderMenuOnMount
                         onMouseEnter={e => windowSize[0] >= 768 && navDropdownShowHandler(e)}
                         onMouseLeave={e => windowSize[0] >= 768 && navDropdownShowHandler(e)}
                         onSelect={e => windowSize[0] >= 768 && navDropdownShowHandler(e)}
                         onClick={e => navDropdownShowHandler(e)}>
                <div className="bg-white py-2 shadow-sm">
                    <Container fluid="lg">
                        {
                            has3Level ? (
                                <Row>
                                    <Col lg={hasPhoto ? 7 : 12}>
                                        <Row>
                                            {
                                                item.children.slice(0, hasPhoto ? childrenLength - 1 : 4).map(item2 => {
                                                    return (
                                                        <Col xs key={item2.id}>
                                                            <NavDropdown.Header className="fw-500 mb-3 text-body lh-1 px-0 pb-1">{item2.title}</NavDropdown.Header>
                                                            {
                                                                item2.children.map(item3 => {
                                                                    return (
                                                                        <NavDropdown.Item as={Link} to={item3.link} eventKey={item2.id} className="px-0" key={item3.id}>{item3.title}</NavDropdown.Item>
                                                                    )
                                                                })
                                                            }
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Col>
                                    {
                                        hasPhoto ? (
                                            <Col xs={5} className="d-none d-lg-flex flex-column ms-auto ad">
                                                <NavDropdown.Header className="fw-500 mb-3 text-body lh-1 px-0 pb-1">{item.children[childrenLength - 1].title}</NavDropdown.Header>
                                                <NavDropdown.Item as={Link} to={item.children[childrenLength - 1].children[0].link} eventKey={item.id} className="d-flex flex-column px-0 flex-grow-1">
                                                    <figure className="mb-2 w-100 bg-black bg-opacity-4 flex-grow-1">
                                                        <span className="d-block h-100 filter-warm">
                                                            <span className="filter-level"/>
                                                            <img src={`${GlobalConst.cdn}${item.children[childrenLength - 1].children[0].photos[0].path}`} alt="" className="h-100 w-100 object-fit-cover"/>
                                                        </span>
                                                    </figure>
                                                    <span>{item.children[childrenLength - 1].children[0].title}</span>
                                                </NavDropdown.Item>
                                            </Col>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    <Col xs={12} className="pt-2 mt-1">
                                        <NavDropdown.Item as={Link} to={`${item.link}/directory`} eventKey={`directory${item.id}`} className="btn text-white bg-primary mt-3 fs-8 ls-half ws-n1 view-all">مشاهده همه</NavDropdown.Item>
                                    </Col>
                                </Row>
                            ) : (
                                item.children.map(item2 => {
                                    return item2.title === 'entertainment' ? (
                                        <Row key={item2.id}>
                                            <Col lg={12}>
                                                <Row className="g-3">
                                                    {
                                                        data?.entertainment?.map(item3 => {
                                                            return (
                                                                <Col xs={2} key={item3.id}>
                                                                    <NavDropdown.Item as={Link} to={`/entertain/${item3.id}`} eventKey={item3.id} className="px-0">
                                                                        <figure className="mb-1 ratio ratio-1x1 bg-black bg-opacity-4">
                                                                            <span className="filter-warm">
                                                                                <span className="filter-level"/>
                                                                                <img src={`${GlobalConst.cdn}${item3.photos[0].path}`} alt="" className="h-100 w-100 object-fit-cover"/>
                                                                            </span>
                                                                        </figure>
                                                                        {item3.name}
                                                                    </NavDropdown.Item>
                                                                </Col>
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <NavDropdown.Item as={Link} to={item2.link} eventKey={item2.id} className="d-flex align-items-center px-0" key={item2.id}>{item2.title}</NavDropdown.Item>
                                    )
                                })
                            )
                        }
                    </Container>
                </div>
            </NavDropdown>
        ) : (
            <Nav.Link as={Link} to={item.link} eventKey={item.id} className="d-flex align-items-center p-0 position-relative">{item.title}</Nav.Link>
        )

    }

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <Container fluid="lg" className="py-3 py-md-0">
            <Nav className="d-flex justify-content-center">
                {
                    data?.menu ? (
                        data.menu.slice(0, -3).map(item => {
                            return (
                                <React.Fragment key={item.id}>
                                    {renderMenu(item)}
                                </React.Fragment>
                            )
                        })
                    ) : (
                        <></>
                    )
                }
            </Nav>
        </Container>
    )
}

export default HeaderRow2;