import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import PrismaZoom from 'react-prismazoom';

import {useHttpClient} from '../../../common/hook/http';

import GlobalConst from '../../../common/global-const';

import {useFrom} from '../../../common/hook/form';
import FormElement from '../../../common/component/FormElement/FormElement';
import {useWindowSize} from '../../../common/hook/window-size';

import OffcanvasMap from '../../offcanvas/Map/Map';

import Loader from '../../../common/component/Loader/Loader';

import {Modal, Container, Row, Col, Button, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';

import {ReactComponent as ImgMapBg} from '../../../asset/img/map/bg.svg';
import * as IconFi from 'react-icons/fi';

import './Map.scss';

const ModalMap = props => {
    const {callAPI} = useHttpClient()
    const navigate = useNavigate()
    const refPrismaZoom = useRef();
    let windowWidth = useWindowSize()[0];

    // ------------------------------------------------------------------------------------------------------------ data

    const [data, setData] = useState(null)
    const [floorCurrent, setFloorCurrent] = useState({value: null, svg: null})

    // ------------------------------------------------------------------------------------------------ on show and hide

    const handlePopstate = useCallback(res => {

        // چک شد که offcanvasMap باعث navigate(-1) شده یا خیر
        if ((!window.location.hash.includes('modalMap') && !window.location.hash.includes('offcanvasMap')) || res === 'hideModal') {
            if (document.querySelectorAll('.modal').length < 2)
                window.removeEventListener('popstate', handlePopstate)

            props.onHide()
        }

    }, [])

    const onShowHandler = () => {

        document.querySelector('#modalMap .modal-header').style.paddingRight = document.querySelector('body').style.paddingRight ? document.querySelector('body').style.paddingRight : 0
        document.querySelector('#modalMap .modal-body').style.paddingRight = document.querySelector('body').style.paddingRight ? document.querySelector('body').style.paddingRight : 0

        navigate(`#modalMap`)
        window.addEventListener('popstate', handlePopstate);

        // با هر بار باز شدن مودال دریافت اطلاعات نمایش داده شود
        setData(null)

        // دریافت اطلاعات هنگام باز شدن مودال
        const fetchData = async () => {
            try {
                const responseData = await callAPI(
                    `parameters/shops`,
                );

                // console.log(responseData)

                if (responseData.message) {

                } else {

                    // بعدا با استفاده از jQuery wayfinding این کدها حذف می شوند
                    responseData['svg'] = await Promise.all(responseData.floors.map(async item => {
                        const svg = await fetch(`${GlobalConst.cdn}maps/floor-${item.value}.svg?${Date.now()}`);
                        // if (svg.status !== 200) return ReactDOMServer.renderToString(<div className="d-flex flex-column align-items-center text-danger"><IconFi.FiAlertTriangle size={48} className="sw-1 mb-3"/><span className="fs-5">اطلاعات نقشه دریافت نشد</span></div>)
                        if (svg.status !== 200) return null
                        return svg.text();
                    }));

                    setData(responseData)

                    // متغیر responseData ارسال شد چون هنوز data بروز نشده است
                    setFloorHandler(responseData.floors.find(({active}) => Number(active)).value, responseData)

                }

            } catch (e) {
                console.error(e);
            }
        }

        fetchData()

    }

    const onHideHandler = () => {

        navigate(-1)

    }

    // --------------------------------------------------------------------------------------------------- map offcanvas

    const [offcanvasData, setOffcanvasData] = useState(null);
    const [offcanvasMapShow, setOffcanvasMapShow] = useState(false);

    const offcanvasMapShowHandler = useCallback(item => {

        let shopTemp = null

        if (item.target) {

            // if (item.target.nodeName.toLowerCase() === 'text' || (item.target.nodeName.toLowerCase() === 'path' && item.target.id !== '' && !item.target.classList.contains('active-click') && !item.target.classList.contains('active-search'))) {
            if (item.target.nodeName.toLowerCase() === 'path' && item.target.id !== '' && !item.target.classList.contains('active-click') && !item.target.classList.contains('active-search')) {

                if (document.querySelector('#modalMap #map svg .active-search')) document.querySelector('#modalMap #map svg .active-search').classList.remove('active-search')
                if (document.querySelector('#modalMap #map svg .active-click')) document.querySelector('#modalMap #map svg .active-click').classList.remove('active-click')

                // if (item.target.nodeName.toLowerCase() === 'text') {
                //
                //     item.target.previousElementSibling.classList.add('active-click')
                //     shopTemp = item.target.previousElementSibling.id.replace('path', '')
                //
                // } else {

                    item.target.classList.add('active-click')
                    shopTemp = item.target.id.replace('path', '')

                // }

            }

        } else {

            if (document.querySelector('#modalMap #map svg .active-search')) document.querySelector('#modalMap #map svg .active-search').classList.remove('active-search')
            if (document.querySelector('#modalMap #map svg .active-click')) document.querySelector('#modalMap #map svg .active-click').classList.remove('active-click')

            // تاخیر برای رندر نفشه طبقه جدید
            let timeout = setTimeout(() => {

                setFloorHandler(data.floors.find(({label}) => label === data.shops.find(({value}) => value === item).floor).value)

                if (document.querySelector(`#modalMap #map svg #path${item}`)) {

                    shopTemp = item
                    document.querySelector(`#modalMap #map svg #path${item}`).classList.add('active-search')

                } else setOffcanvasMapShow(false)

                clearTimeout(timeout)

            }, 0)

        }

        // تاخیر برای مقدار گرفتن shopTemp
        let timeout = setTimeout(() => {

            if (shopTemp) {

                setOffcanvasData(() => {
                    return data.shops.find(({value}) => value === shopTemp)
                })

                // بدون شرط هش offcanvasMap بارها تکرار می شود
                if (!offcanvasMapShow) setOffcanvasMapShow(true);

            }

            clearTimeout(timeout)

        }, .1)

    }, [data])

    const [formMapSearchClear, setFormMapSearchClear] = useState(false)

    const offcanvasMapHideHandler = res => {

        if (document.querySelector('#modalMap #map svg .active-search')) document.querySelector('#modalMap #map svg .active-search').classList.remove('active-search')
        if (document.querySelector('#modalMap #map svg .active-click')) document.querySelector('#modalMap #map svg .active-click').classList.remove('active-click')

        if (document.querySelector('#modalMap #formMapSearch .react-select__clear-indicator')) {
            setFormMapSearchClear(true)
            setFormMapSearchClear(false)
        }

        setOffcanvasMapShow(false)

        // اگر res مقدار داشته باشد به معنی کلیک روی دکمه more و انتقال به صفحه غرفه می باشد
        if (res) handlePopstate(res)

    }

    // ---------------------------------------------------------------------------------------- floor, search and filter

    const [formState, inputHandler] = useFrom({
        formMapSearch: {
            value: '',
            isValid: true
        },
        formMapFloor: {
            value: '',
            isValid: true
        }
    }, true)

    useEffect(() => {

        if (data && formState.inputs.formMapSearch.value && formState.inputs.formMapSearch.value !== '') offcanvasMapShowHandler(formState.inputs.formMapSearch.value)

    }, [formState.inputs.formMapSearch.value])

    const [filters, setFilters] = useState([
        {id: '1', title: 'خرید کنید', value: 'filter1', active: false},
        {id: '2', title: 'میل کنید', value: 'filter2', active: false},
        {id: '3', title: 'تفریح کنید', value: 'filter3', active: false}
    ])

    const setFilterHandler = filter => {

        let $elements = document.querySelectorAll(`#modalMap #map svg [data-category=${filter}]`)

        for (const item of $elements) item.classList.toggle('active-filter');

        let filtersTemp = [...filters]

        filtersTemp.find(({value}) => value === filter).active = !filtersTemp.find(({value}) => value === filter).active

        setFilters(filtersTemp)

    }

    const handlePathTitle = ($path, item) => {

        let bbox = $path.getBBox();
        let pathCenterX = bbox.x + bbox.width / 2;
        let pathCenterY = bbox.y + bbox.height / 2;

        let $text = document.createElementNS($path.namespaceURI, 'text');

        $text.setAttribute('x', pathCenterX);
        $text.setAttribute('y', pathCenterY);
        $text.setAttribute('text-anchor', 'middle');
        $text.setAttribute('alignment-baseline', 'central');
        $text.setAttribute('dominant-baseline', 'central');
        $text.textContent = item.label;
        $text.setAttribute('transform', `translate(${item.parameters[1] ? item.parameters[1] : 0} ${item.parameters[2] ? item.parameters[2] : 0}) rotate(${item.parameters[3] ? item.parameters[3] : 0} ${pathCenterX} ${pathCenterY})`);

        $path.after($text);

        $text.setAttribute('style', `font-size: ${((bbox.width * 90 / 100) * ((item.parameters[0] ? item.parameters[0] : 100) / 100)) / $text.getBBox().width}em`);

    }

    const handlePathLogo = ($path, item) => {

        let bbox = $path.getBBox();
        let pathCenterX = bbox.x + bbox.width / 2;
        let pathCenterY = bbox.y + bbox.height / 2;
        let imageWidth = (bbox.width * 80 / 100) * (item.parameters[0] / 100);

        let $image = document.createElementNS($path.namespaceURI, 'image');

        $image.setAttribute('href', `${GlobalConst.cdn}${item.logo}`);
        $image.setAttribute('width', imageWidth);
        $image.setAttribute('x', pathCenterX - (imageWidth / 2));
        $image.setAttribute('visibility', 'hidden');

        $image.setAttribute('transform', `translate(${item.parameters[1]} ${item.parameters[2]}) rotate(${item.parameters[3]} ${pathCenterX} ${pathCenterY})`);

        $path.after($image);

        $image.onload = () => {
            let timeout = setTimeout(() => {
                $image.setAttribute('y', pathCenterY - ($image.getBBox().height / 2))
                $image.removeAttribute('visibility')
                clearTimeout(timeout)
            }, 0)
        };

    }

    const setFloorHandler = useCallback ((floor, parameter2) => {

        // زمان تغییر ToggleButtonGroup مقدار event بعنوان parameter2 دریافت می گردد
        let dataTemp = parameter2 && !parameter2.type ? {...parameter2} : {...data}

        setFloorCurrent({value: floor, svg: dataTemp.svg[floor - 1]})

        // ریست کردن فیلتر
        if (filters.find(({active}) => active)) {
            let filtersTemp = [...filters]

            filtersTemp = filtersTemp.map(item => {
                item.active = false;
                return item
            })

            setFilters(filtersTemp)
        }

        // اگر فقط طبقات دستی انتخاب شوند
        if (parameter2?.type) {

            // ریست کردن مقدار جستجو
            if (document.querySelector('#modalMap #formMapSearch .react-select__clear-indicator')) {
                setFormMapSearchClear(true)
                setFormMapSearchClear(false)
            }

            // بستن setOffcanvasMapShow
            if (offcanvasMapShow) document.querySelector('#offcanvasMap [aria-label="Close"]').click();

        }

        // درج اطلاعات فیلتر روی نقشه
        let timeout = setTimeout(() => {

            let shopsTemp = dataTemp.shops.filter(item => item.floor === dataTemp.floors[floor - 1].label)

            shopsTemp.forEach((item, index) => {

                if (document.querySelector(`#modalMap #map svg path#path${item.value}`)) {

                    let tempPath = document.querySelector(`#modalMap #map svg path#path${item.value}`)

                    tempPath.dataset.category = `filter${item.type}`

                    if (item.logo) handlePathLogo(tempPath, item);
                    else handlePathTitle(tempPath, item);


                }

                if (index + 1 === shopsTemp.length) clearTimeout(timeout)

            })

        }, 0)

        if (refPrismaZoom.current?.getZoom() > 1) refPrismaZoom.current.reset()

    }, [data, filters, offcanvasMapShow])

    useEffect(() => {

        // بار اول و زمان تغییر عرض صفحه تابع فراخوانی نشود
        if (floorCurrent.value && formState.inputs.formMapFloor.value && formState.inputs.formMapFloor.value !== '' && !formState.inputs.formMapFloor.value.value) {

            let valueTemp = formState.inputs.formMapFloor.value

            if (valueTemp.value) valueTemp = valueTemp.value

            // مقدار simulation ارسال شد تا event تشخیص داده شود
            setFloorHandler(valueTemp, {type: 'simulation'})

        }

    }, [formState.inputs.formMapFloor.value])

    // ------------------------------------------------------------------------------------------------------------ zoom

    const zoomIn = () => {
        refPrismaZoom.current.zoomIn(1)
    }

    const zoomOut = () => {
        refPrismaZoom.current.zoomOut(1)
    }

    // -----------------------------------------------------------------------------------------------------------------

    return (
        // چون نوار بالا رنگی است، کلاس px-0 استفاده شد
        <>
            <Modal id="modalMap" aria-labelledby="modalMapTitle" {...props} fullscreen backdrop={false} onHide={onHideHandler} onShow={onShowHandler} className="ltr" dialogClassName="rtl">
                <Modal.Header className="pe-0 bg-primary">
                    <Container fluid="lg" className="position-relative d-flex align-items-center justify-content-between">
                        <Button variant="link" className="border-0 lh-0 text-white p-2 me-2 d-md-none" aria-label="Close" onClick={onHideHandler}><IconFi.FiArrowRight size={24} className="sw-1"/></Button>
                        <Modal.Title as="div" id="modalMapTitle" className="flex-grow-1">
                            {
                                data ? (
                                    <FormElement component="SELECT" id="formMapSearch" name="search" options={data.shops} onInput={inputHandler} searchable searchIcon clearable clear={formMapSearchClear} placeholder="جستجوی میکا مال" className="border-0"/>
                                ) : (
                                    <div className="text-truncate fs-5 text-white">نقشه میکامال</div>
                                )
                            }
                        </Modal.Title>
                        <Button variant="link" className="border-0 lh-0 text-white p-2 ms-2 d-none d-md-inline-flex" aria-label="Close" onClick={onHideHandler}><IconFi.FiX size={24} className="sw-1"/></Button>
                        {
                            data && (
                                <div className="position-absolute start-0 end-0 top-100 px-2 mx-1 mt-3 header-bottom">
                                    <Row className="gx-2">
                                        <Col xs="auto" className="position-relative order-md-last">
                                            <div id="floor">
                                                {
                                                    windowWidth < 768 ? (
                                                        <FormElement component="SELECT" id="formMapFloor" name="floor" options={data.floors} editValue={data.floors.find(({value}) => value === floorCurrent.value)?.value} onInput={inputHandler} placeholder="طبقه"/>
                                                    ) : (
                                                        <ToggleButtonGroup type="radio" name="radioFloor" value={floorCurrent.value} onChange={setFloorHandler} vertical className="bg-white p-2 shadow-sm">
                                                            <div className="align-self-stretch text-black text-opacity-54 text-center">طبقه</div>
                                                            <hr className="align-self-stretch mx-n2 my-2"/>
                                                            {
                                                                data.floors.map(item => {
                                                                    return (
                                                                        <ToggleButton id={`radioFloor${item.value}`} value={item.value} variant={item.value === floorCurrent.value ? 'accent' : 'link'}
                                                                                      className={`font-en ls-1 text-decoration-none${item.value === floorCurrent.value ? ' text-white' : ''}`} key={item.value}>{item.shortTitle}</ToggleButton>
                                                                    )
                                                                })
                                                            }
                                                        </ToggleButtonGroup>
                                                    )
                                                }
                                            </div>
                                        </Col>
                                        <Col>
                                            <div id="filter" className="shadow-sm bg-white p-2">
                                                <Row className="gx-2">
                                                    {
                                                        filters.map(item => {
                                                            return (
                                                                <Col className="col d-grid" key={item.id}>
                                                                    <Button type="button" variant={item.active ? 'accent' : 'link'} className="text-decoration-none px-0 fs-18px" onClick={() => setFilterHandler(item.value)}>{item.title}</Button>
                                                                </Col>
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }
                    </Container>
                </Modal.Header>
                {
                    data ? (
                        <Modal.Body className="py-0 pe-0 overflow-hidden">
                            {
                                floorCurrent.svg ? (
                                    <>
                                        <Container fluid="lg" className="position-relative">
                                            <div id="zoom" className="d-flex flex-column shadow-sm bg-white position-absolute end-0 z-index-1">
                                                <Button variant="link" className="btn-icon" onClick={() => zoomIn()}><IconFi.FiPlus size={24} className="sw-1"/></Button>
                                                <hr className="my-0"/>
                                                <Button variant="link" className="btn-icon" onClick={() => zoomOut()}><IconFi.FiMinus size={24} className="sw-1"/></Button>
                                            </div>
                                        </Container>
                                        <PrismaZoom className="h-100 d-flex justify-content-center prisma-zoom" ref={refPrismaZoom} allowTouchEvents>
                                            <div className="h-100 position-relative">
                                                <ImgMapBg className="position-absolute top-50 start-50 z-index-0 text-accent-3 text-opacity-38 map-bg"/>
                                                <div id="map" className="d-flex align-items-center h-100 position-relative z-index-1" dangerouslySetInnerHTML={{__html: floorCurrent.svg}} onClick={offcanvasMapShowHandler}/>
                                            </div>
                                        </PrismaZoom>
                                    </>
                                ) : (
                                    <div className="h-100 d-flex flex-column justify-content-center align-items-center text-danger"><IconFi.FiAlertTriangle size={48} className="sw-1 mb-3"/><span className="fs-5">اطلاعات نقشه دریافت نشد</span></div>
                                )
                            }
                        </Modal.Body>
                    ) : (
                        <Modal.Body className="d-flex justify-content-center align-items-center">
                            <Loader text="در حال دریافت اطلاعات"/>
                        </Modal.Body>
                    )
                }
            </Modal>
            <OffcanvasMap show={offcanvasMapShow} onHide={res => offcanvasMapHideHandler(res)} data={offcanvasData}/>
        </>
    )
}

export default ModalMap;