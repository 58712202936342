import React from 'react';

import {Row, Col, Nav} from 'react-bootstrap';

import {ReactComponent as ImgLogo} from '../../../asset/img/micamall-logo-sign.svg';

import {ReactComponent as ImgExternalLink} from '../../../asset/img/icon/external-link.svg';
import * as IconFi from 'react-icons/fi';
// import * as IconFa from 'react-icons/fa';
// import * as IconSi from 'react-icons/si';
// import * as IconGr from 'react-icons/gr';

const FooterRow1 = () => {

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <Row className="text-center text-lg-start">
            <Col xs={12}>
                <div className="p-3 bg-black bg-opacity-4 text-white py-3 d-flex align-items-center app">
                    <a href="https://my.micamall.org/" target="_blank" className="d-flex align-items-center text-decoration-none fs-18px logo">
                        <figure className="mb-0 bg-primary ratio ratio-1x1 rounded-square"><span className="d-flex align-items-center"><ImgLogo className="img-fluid"/></span></figure>
                        <span className="text-primary ms-3 ws-n1">اپلیکیشن میکا مال من</span>
                    </a>
                    <Nav className="ms-auto">
                        <Nav.Link href="https://my.micamall.org/" target="_blank" className="d-flex align-items-center rounded-6 border border-primary py-0 px-sm-3">
                            <ImgExternalLink className="sw-1 me-2 d-none d-sm-block"/>
                            <span className="d-none d-sm-block">نسخه تحت وب</span>
                            <IconFi.FiArrowLeft size={24} className="sw-1 d-sm-none"/>
                        </Nav.Link>
                    </Nav>
                </div>
            </Col>
            {/*<Col lg={9} className="app">*/}
            {/*    <h6>دانلود اپلیکیشن میکا مال من</h6>*/}
            {/*    <Nav className="justify-content-center justify-content-lg-start fw-bold pt-2">*/}
            {/*        <Nav.Link href="#"*/}
            {/*                  className="py-2 px-2 px-md-3 d-flex align-items-center border border-dark rounded-6 text-reset mx-1 mt-1 ms-lg-0">*/}
            {/*            <IconFa.FaGooglePlay size={27} className="me-2 me-md-3"/>*/}
            {/*            <span className="d-flex flex-column align-items-start">نسخه Android<span*/}
            {/*                className="fs-10 mt-n1 fw-500"*/}
            {/*                style={{marginLeft: '3px'}}>دریافت از Google Play</span></span>*/}
            {/*        </Nav.Link>*/}
            {/*        <Nav.Link href="#"*/}
            {/*                  className="py-2 px-2 px-md-3 d-flex align-items-center border border-dark rounded-6 text-reset mx-1 mt-1">*/}
            {/*            <IconSi.SiApple size={29} className="me-2 me-md-3" style={{marginTop: '-2px'}}/>*/}
            {/*            <span className="d-flex flex-column align-items-start">نسخه iOS<span*/}
            {/*                className="fs-10 mt-n1 fw-500" style={{marginLeft: '4px'}}>دریافت از App Store</span></span>*/}
            {/*        </Nav.Link>*/}
            {/*    </Nav>*/}
            {/*</Col>*/}
            {/*<Col lg={3} className="mt-4 mt-lg-0 pt-2 pt-lg-0">*/}
            {/*    <h6>همراه ما باشید</h6>*/}
            {/*    <Nav className="justify-content-center justify-content-lg-start lh-1 ms-lg-n2">*/}
            {/*        <Nav.Link href="#" className="p-2 text-reset mx-1"><IconFi.FiInstagram size={24}/></Nav.Link>*/}
            {/*        <Nav.Link href="#" className="p-2 text-reset mx-1"><IconGr.GrTwitter size={24}/></Nav.Link>*/}
            {/*        <Nav.Link href="#" className="p-2 text-reset mx-1"><IconGr.GrFacebookOption size={24}/></Nav.Link>*/}
            {/*    </Nav>*/}
            {/*</Col>*/}
        </Row>
    )
}

export default FooterRow1;