const GlobalConst = {
    ownerShort: 'میکا مال',
    owner: 'مجموعه تجاری تفریحی میکا مال',
    ownerCompany: 'مجموعه تجاری تفریحی میکا مال',
    ownerUrl: 'https://www.micamall.com/',
    appTitle: 'پورتال عمومی میکا مال',
    appTitleShort: 'میکا مال',
    api: 'https://api.micamall.org/',
    // api: 'http://localhost:3050/',
    cdn: 'https://cdn.micamall.org/',
    currency: 'ریال',
    version: '0.1.0',
    days: [
        {fa: 'یک شنبه', en: 'Sun'},
        {fa: 'دوشنبه', en: 'Mon'},
        {fa: 'سه شنبه', en: 'Tues'},
        {fa: 'چهارشنبه', en: 'Wed'},
        {fa: 'پنج شنبه', en: 'Thurs'},
        {fa: 'جمعه', en: 'Fri'},
        {fa: 'شنبه', en: 'Sat'},
    ],
    splide: {
        type: 'loop',
        direction: 'rtl',
        autoplay: true,
        interval: 6000,
        easing: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
        speed: 800,
        arrowPath: 'M 15.476562 10.5 A 0.5 0.5 0 0 0 15.146484 10.646484 A 0.5 0.5 0 0 0 15.146484 11.353516 L 23.792969 20 L 15.146484 28.646484 A 0.5 0.5 0 0 0 15.146484 29.353516 A 0.5 0.5 0 0 0 15.853516 29.353516 L 24.853516 20.353516 A 0.50005 0.50005 0 0 0 24.853516 19.646484 L 15.853516 10.646484 A 0.5 0.5 0 0 0 15.476562 10.5 z'
    },
    plyr: {
        controls: ['play-large', 'play', 'progress', 'fullscreen'],
        hideControls: false,
        blankVideo: `https://cdn.micamall.org/public-portal/video/tv-white-noise.mp4`,
        loadSprite: false
    },
    education: [
        {value: 1, label: `دیپلم`},
        {value: 2, label: `کاردانی`},
        {value: 3, label: `کارشناسی`},
        {value: 4, label: `کارشناسی ارشد`},
        {value: 5, label: `دکتری`},
    ],
    optionsYesNo: [{value: 1, label: 'بلی'}, {value: 0, label: 'خیر'}],
    optionsGender: [{value: '2', label: 'خانم'}, {value: '1', label: 'آقا'}],
    ipg: 'https://ikc.shaparak.ir/iuiv3/IPG/Index/',
    buyTicketOnline: 'خـرید آنلاین بلیـط',
}

export default GlobalConst;