import React, {useState, useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';

import {ContextAuth} from '../../../common/context/context-auth';

import {useWindowSize} from '../../../common/hook/window-size'

import ModalSearch from '../../modal/Search/Search';
import SearchBtn from '../../modal/Search/SearchBtn';
import ModalLogin from '../../modal/Login/Login';

import {Navbar, Container, Nav, NavDropdown, Button} from 'react-bootstrap';

import {ReactComponent as ImgLogoH} from '../../../asset/img/micamall-logo-h.svg';

import {ReactComponent as ImgAccount} from '../../../asset/img/icon/account.svg';
import {ReactComponent as ImgAccountFill} from '../../../asset/img/icon/account-fill.svg';
import {ReactComponent as ImgCustomerClub} from '../../../asset/img/icon/customer-club.svg';
import * as IconFi from 'react-icons/fi';

const HeaderRow1 = () => {
    // const location = useLocation()
    const auth = useContext(ContextAuth);
    const windowSize = useWindowSize()

    // ---------------------------------------------------------------------------------------------------- modal search
    // از کامپوننت SearchBtn استفاده شد تا در دو موقعیت در هدر نمایش داده شود
    // به جای dropdown از modal استفاده شد تا اسکرول صفحه را غیرفعال کند
    const [modalSearchShow, setModalSearchShow] = useState(false);

    // اگر مودال سرچ باز باشد و زبان انتخاب شود، انتخاب دوباره روی زبان می رود
    // const dropdownLanguageShowHandler = () => {
    //
    //     if (location.hash === '#modalSearch')
    //         document.querySelector('#navbarLanguageDropdown').click()
    //
    // }

    // ----------------------------------------------------------------------------------------------------- modal login
    const [modalLoginShow, setModalLoginShow] = useState(false);

    // -----------------------------------------------------------------------------------------------------------------

    useEffect(() => {

        const handleMenuHide = () => {

            if(!document.querySelector('[aria-controls="navbarCollapse"]').classList.contains('collapsed'))
                document.querySelector('[aria-controls="navbarCollapse"]').click()

        }

        if (document.querySelector('#navbarAccountDropdown')) {

            document.querySelector('#navbarAccountDropdown').addEventListener('click', handleMenuHide);

            return () => document.querySelector('#navbarAccountDropdown').removeEventListener('click', handleMenuHide);

        }

    }, []);

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <>
            <Container fluid="lg" className="pb-2 pb-md-4 d-grid" style={{gridTemplateColumns: '1fr 1fr 1fr'}}>
                <Navbar.Toggle aria-controls="navbarCollapse" className="border-0 p-2 shadow-none" style={{justifySelf: 'start'}}><IconFi.FiMenu size={24} className="sw-1"/></Navbar.Toggle>
                <Nav className="d-none d-md-flex lh-1">
                    {
                        windowSize[0] >= 768 && (
                            <SearchBtn show={modalSearchShow} onShow={() => setModalSearchShow(true)}/>
                        )
                    }
                    {/*<NavDropdown id="navbarLanguageDropdown" title={<IconFi.FiGlobe size={24} className="sw-1"/>} onClick={dropdownLanguageShowHandler}>*/}
                    {/*    <NavDropdown.Item href="#" className="d-flex align-items-center">العربية</NavDropdown.Item>*/}
                    {/*    <NavDropdown.Item href="#" className="d-flex align-items-center text-end">English</NavDropdown.Item>*/}
                    {/*</NavDropdown>*/}
                </Nav>
                <Navbar.Brand as={Link} to="/" className="me-0 py-0" style={{justifySelf: 'center'}}>
                    <ImgLogoH/>
                </Navbar.Brand>
                <Nav className="d-flex flex-row" style={{justifySelf: 'end'}}>
                    {
                        windowSize[0] < 768 && (
                            <SearchBtn show={modalSearchShow} onShow={() => setModalSearchShow(true)}/>
                        )
                    }
                    {
                        auth.user ? (
                            <NavDropdown id="navbarAccountDropdown" title={<><span className="d-none d-md-block me-1 fs-7">خوش آمدید</span><span><figure className="m-1 rounded-square bg-black bg-opacity-4 overflow-hidden"><ImgAccountFill width={34} height={34} stroke="none" fill="rgba(0, 0, 0, .12)"/></figure></span></>} align="end">
                                <div className="d-flex flex-column align-items-center py-4 mb-2 mt-n2 bg-accent text-white">
                                    <figure style={{backgroundImage: 'url()'}} className="rounded-square bg-white ratio ratio-1x1 mb-2 mt-1">
                                        <div className="d-flex align-items-end justify-content-end">
                                            <Button as={Link} to="/account/profile" variant="link" className="rounded-square bg-white me-n1 mb-n1 shadow-sm btn-icon" onClick={() => document.querySelector('#navbarAccountDropdown').click()}><IconFi.FiCamera size={18} className="sw-1"/></Button>
                                        </div>
                                    </figure>
                                    <div className="mt-1">{auth.user.name ? `${auth.user.name} ${auth.user.last_name}` : 'کاربر مهمان'}</div>
                                    <div className="ls-half lh-1 fs-18px">0{auth.user.mobile}</div>
                                </div>
                                <NavDropdown.Item as={Link} to="/account/customer-club" className="d-flex align-items-center"><ImgCustomerClub className="sw-1 me-3"/>باشگاه مشتریان</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item as={Link} to="/account" className="d-flex align-items-center"><ImgAccount className="sw-1 me-3"/>حساب کاربری</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <div className="d-grid px-3 py-2">
                                    <Button type="button" variant="link" className="text-reset bg-black bg-opacity-4 text-decoration-none" onClick={()=>auth.logout()}>خروج از حساب کاربری</Button>
                                </div>
                            </NavDropdown>
                        ) : (
                            <Button type="button" id="accountBtn" variant="link" className="text-reset d-flex align-items-center border-0 p-2 shadow-none nav-link btn-icon" onClick={() => setModalLoginShow(true)}>
                                <span className="d-none d-md-block me-2 fs-7">ورود کاربران</span>
                                <ImgAccount className="sw-1"/>
                            </Button>
                        )
                    }

                </Nav>
            </Container>
            <ModalSearch show={modalSearchShow} onHide={() => setModalSearchShow(false)}/>
            <ModalLogin show={modalLoginShow} onHide={() => setModalLoginShow(false)}/>
        </>
    )
}

export default HeaderRow1;