import React from 'react';

import {ReactComponent as ImgLoader} from '../../../asset/img/icon/loader.svg';

import './Loader.scss';

const Loader = props => {

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <div className="d-flex flex-column align-items-center text-center loader">
            <ImgLoader width={48} height={48} className="mb-3"/>
            <span className="text-secondary fs-5">{props.text}</span>
        </div>
    )
}

export default Loader;