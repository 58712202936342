import React, {useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';

import ContextBreadcrumb from '../../../common/context/context-breadcrumb';

import {Container, Row, Col, Button} from 'react-bootstrap';

import * as IconFi from 'react-icons/fi';

const Error404 = () => {
    const {callBreadcrumb} = useContext(ContextBreadcrumb);

    useEffect(() => {

        document.documentElement.dataset.page = 'error-404'
        callBreadcrumb('خطای 404')

    }, []);

    // -----------------------------------------------------------------------------------------------------------------

    return (
        <div id="pageError404">
            <Container fluid="lg">
                <Row className="py-5">
                    <Col xs={12} className="d-flex flex-column justify-content-center align-items-center text-center my-5">
                        <h1 className="text-secondary">خطای 404</h1>
                        <p>صفحه مورد نظر شما یافت نشد.</p>
                        <p className="text-black text-opacity-54">آدرس صفحه تغییر پیدا کرده است و یا آنرا اشتباه وارد کرده اید.</p>
                        <Button as={Link} to="/" variant="outline-secondary mt-3"><IconFi.FiArrowRight size={24} className="sw-1 me-2"/>بازگشت به صفحه نخست</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Error404;